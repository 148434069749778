import React from "react"
import PropsTypes from "prop-types";
import { Link, useLocation } from "react-router-dom"
import { Container } from "react-bootstrap"
import Layout from "../layout/layout"
import Gear from "../asset/image/settingIcon/icon-gear.svg"
import profileImg from "../asset/image/settingIcon/profile.svg"
import editIcon from "../asset/image/settingIcon/editicon.svg"
import "./Setting.css"

function Setting({ children }) {
  const location = useLocation();
  return (
    <Layout>
      <Container>
        <div className="settingPage">
          <div className="setting-icon">
            <img src={Gear} alt="Gear" />
            <h4>Settings</h4>
          </div>
          <div className="setting-content">
            <div className="setting-menu">
              <div className="settingMenu-profile">
                <div className="settingmenuImg">
                  <img src={profileImg} alt="" className="profileImg" />
                  <img src={editIcon} alt="" className="editicon" />
                </div>
                <div className="settingMenu-profilename">zafar websul</div>
              </div>
              <div className="settingMenu-tab">
                <ul className="settingMenu-list">
                  <Link to="/generalSetting" className={location.pathname === "/generalSetting" ? "active" : ""}><li>General</li></Link>
                  <Link to="/securitysetting" className={location.pathname === "/securitysetting" ? "active" : ""}>
                    {" "}
                    <li>Security</li>
                  </Link>
                  <Link to="/settingPrivacy" className={location.pathname === "/settingPrivacy" ? "active" : ""}><li>Preferences</li></Link>
                  <Link to="/sessionSetting" className={location.pathname === "/sessionSetting" ? "active" : ""}><li>Sessions</li></Link>
                </ul>
              </div>

            </div>
            <div className="settingMenu-content">
              {children}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}
Setting.propTypes = {
  children: PropsTypes.element.isRequired
}
export default Setting
