import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import error from "../asset/image/error/404 1.svg"

import "./Error404.css"

function Error404() {
  return (
    <div className="requestNotFound">
      <img src={error} alt="error" />
      <div className="errorMssg">
        Opps! Page Not Found
      </div>
      <div className="errorBtn">
        <Link to="/">
          {" "}
          <Button> BACK TO HOME</Button>
        </Link>
      </div>
    </div>
  )
}

export default Error404
