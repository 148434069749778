import React from "react";
import user from "../asset/image/itworks/user.svg";
import earn from "../asset/image/itworks/earn.svg";
import play from "../asset/image/itworks/play.svg";
import share from "../asset/image/itworks/share.svg";
import workframe from "../asset/image/itworks/workframes.png";
import Layout from "../layout/layout";
import "./howtoplay.css";

function HowToPlay() {
  return (
    <Layout>
      <div className="work-content-container">
        <h2>
          How
          {" "}
          <span>TO PLay</span>
        </h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industrys standard dummy text ever
          since the 1500s,
          {" "}
        </p>
        <div className="work-content">
          <div className="work-content-left">
            <div className="work-content-box">
              <div className="work-create-icon">
                <img src={user} alt="icon" />
              </div>
              <div className="work-box-text">
                <h6>Create Account</h6>
                <p>
                  That it has a more-or-less normal distribution of letters, as
                  opposed to
                </p>
              </div>
            </div>

            <div className="work-content-box">
              <div className="work-earn-icon">
                <img src={earn} alt="icon" />
              </div>
              <div className="work-box-text">
                <h6>Earn Coins</h6>
                <p>
                  That it has a more-or-less normal distribution of letters, as
                  opposed to
                </p>
              </div>
            </div>

            <div className="work-content-box">
              <div className="work-play-icon">
                <img src={play} alt="icon" />
              </div>
              <div className="work-box-text">
                <h6>Play Game</h6>
                <p>
                  That it has a more-or-less normal distribution of letters, as
                  opposed to
                </p>
              </div>
            </div>

            <div className="work-content-box">
              <div className="work-share-icon">
                <img src={share} alt="icon" />
              </div>
              <div className="work-box-text">
                <h6>Share With Friends</h6>
                <p>
                  That it has a more-or-less normal distribution of letters, as
                  opposed to
                </p>
              </div>
            </div>
          </div>
          <div className="work-content-right">
            <img src={workframe} alt="frame" />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default HowToPlay;
