import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import PhoneInput from "react-phone-input-2";
import sms from "../../asset/image/LoginBackground/sms.svg";
import key from "../../asset/image/LoginBackground/key.svg";
import Setting from "../Setting";

import "./GeneralSetting.css";

function GeneralSetting() {
  const [value, onChange] = useState(new Date());
  return (
    <Setting>
      <div className="generalSet-container">
        <div className="basicDetails">
          <Form>
            <div className="basicDetails-name">
              <Form.Group className="generalSet-name">
                <Form.Label>
                  First Name
                  {" "}
                  <span>*</span>
                </Form.Label>
                <Form.Control type="name" placeholder="Zafar" />
              </Form.Group>
              <Form.Group className="generalSet-name">
                <Form.Label>
                  Last Name
                  {" "}
                  <span>*</span>
                </Form.Label>
                <Form.Control type="name" placeholder="Khan" />
              </Form.Group>
            </div>
            <Form.Group className="generalSet-mail">
              <Form.Label>
                Email
                {" "}
                <span>*</span>
              </Form.Label>
              <img src={sms} alt="" />
              <Form.Control type="email" placeholder="example@mail.com" />
            </Form.Group>

            <Form.Group className="generalSet-DOB">
              <Form.Label>
                DOB
                {" "}
                <span>*</span>
              </Form.Label>
              <DatePicker onChange={onChange} value={value} />
            </Form.Group>

            <Form.Group className="generalSet-phone">
              <Form.Label>Phone Number </Form.Label>
              <PhoneInput
                placeholder="Enter phone number"
                value="+91"
                searchPlaceholder=""
                className="generalSet-phone"
              />
            </Form.Group>

            <Button className="generalSet-btn">Save</Button>
          </Form>
        </div>
        <div className="resetChange">
          <Form>
            <p> Reset Password</p>

            <Form.Group className="reg-passwd">
              <Form.Label>
                Password
                {" "}
                <span>*</span>
              </Form.Label>
              <img src={key} alt="" />
              <Form.Control
                type="password"
                placeholder="••••••••"
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group className="reg-passwd">
              <Form.Label>
                Confirm Password
                {" "}
                <span>*</span>
              </Form.Label>
              <img src={key} alt="" />
              <Form.Control
                type="password"
                placeholder="••••••••"
                autoComplete="off"
              />
            </Form.Group>

            <Button className="generalSet-btn">Save</Button>
          </Form>
        </div>
      </div>
    </Setting>
  );
}

export default GeneralSetting;
