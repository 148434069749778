import React from "react";
import PropsTypes from "prop-types";
import { Offcanvas, Form } from "react-bootstrap";
import UserPic1 from "../asset/image/chat/user1.svg";
import UserPic2 from "../asset/image/chat/user2.svg";
import UserPic3 from "../asset/image/chat/user3.svg";
import UserPic4 from "../asset/image/chat/user4.svg";
import UserPic5 from "../asset/image/chat/user5.svg";
import UserPic6 from "../asset/image/chat/user6.svg";

import "./chat.css";

function GlobalChat({ showChat, handleChatClose }) {
  const ChatMsg = [
    {
      profile: UserPic1,
      name: "Albert Flores",
    },
    {
      profile: UserPic2,
      name: "Qweike",
    },
    {
      profile: UserPic3,
      name: "Bessie Cooper",
    },
    {
      profile: UserPic4,
      name: "Zafar",
    },
    {
      profile: UserPic5,
      name: "Akram",
    },
    {
      profile: UserPic6,
      name: "Bessie Cooper",
    },
    {
      profile: UserPic1,
      name: "Albert Flores",
    },
    {
      profile: UserPic2,
      name: "Qweike",
    },
    {
      profile: UserPic3,
      name: "Bessie Cooper",
    },
  ];
  return (
    <div className="chat-wrapper">
      <Offcanvas
        className="chat-container"
        show={showChat}
        onHide={handleChatClose}
        placement="end"
      >
        <Offcanvas.Header>
          <div className="chat-header">
            <h6>Chat</h6>
            <div className="chat-status">
              <span>256 Online</span>
              <div
                className="arrow-icon"
                onClick={handleChatClose}
                role="presentation"
              >
                <ArrowIcon />
              </div>
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="message-container">
            {ChatMsg.map((item, i) => (
              <div className="message-wrapper" key={`item-${i + 1}`}>
                <img src={item.profile} alt="" />
                <div className="message-body">
                  <div className="chat-user-name">
                    <h6>{item.name}</h6>
                    <span className="chat-time">12:14</span>
                  </div>
                  <div className="user-chat-box">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="send-chat-wrapper">
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Enter your message here..."
              />
              <div className="send-message-btn">
                <span>
                  <EmojiIcon />
                </span>
                <div className="send-chat-icon">
                  <SendBtn />
                </div>
              </div>
            </Form.Group>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

GlobalChat.propTypes = {
  showChat: PropsTypes.bool.isRequired,
  handleChatClose: PropsTypes.func.isRequired
}

export default GlobalChat;

export function ArrowIcon() {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 7.00011C7.5 7.23301 7.41599 7.46596 7.24837 7.64359L1.96805 13.2333C1.63216 13.5889 1.08757 13.5889 0.751815 13.2333C0.416062 12.8778 0.416062 12.3015 0.751815 11.9458L5.42406 7.00011L0.75214 2.05413C0.416387 1.6987 0.416387 1.12223 0.75214 0.766814C1.08789 0.411062 1.63231 0.411062 1.9682 0.766814L7.24855 6.35645C7.41616 6.53416 7.5 6.76713 7.5 7.00011Z"
        fill="var(--light-background)"
      />
    </svg>
  );
}

export function SendBtn() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_149_8187)">
        <path
          d="M11.6618 0.349921C11.5145 0.198737 11.3287 0.0906238 11.1245 0.0372301C10.9202 -0.0161636 10.7053 -0.012813 10.5028 0.0469208L0.84684 2.85592C0.40984 2.97692 0.0998403 3.32592 0.0168403 3.76792C-0.0691597 4.21792 0.22884 4.79092 0.61684 5.02992L3.63684 6.88592C3.94684 7.07592 4.34684 7.02892 4.60284 6.76992L8.05984 3.28992C8.10093 3.24681 8.15035 3.21249 8.2051 3.18904C8.25984 3.16559 8.31878 3.1535 8.37834 3.1535C8.4379 3.1535 8.49684 3.16559 8.55159 3.18904C8.60633 3.21249 8.65575 3.24681 8.69684 3.28992C8.7804 3.37548 8.82718 3.49033 8.82718 3.60992C8.82718 3.72951 8.7804 3.84436 8.69684 3.92992L5.23284 7.40992C5.10768 7.53568 5.02826 7.69974 5.00723 7.87592C4.98621 8.05209 5.02479 8.23024 5.11684 8.38192L6.96184 11.4319C7.17784 11.7949 7.54984 11.9999 7.95784 11.9999C8.00584 11.9999 8.05984 11.9999 8.10784 11.9939C8.57684 11.9339 8.94784 11.6139 9.08684 11.1609L11.9498 1.51492C12.0758 1.10492 11.9618 0.656921 11.6618 0.348921V0.349921Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_149_8187">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function EmojiIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_149_8182)">
        <path
          d="M15.0112 15.0098C14.2253 15.8142 13.2876 16.4547 12.2524 16.8941C11.2173 17.3335 10.1052 17.5631 8.98065 17.5697C7.8561 17.5762 6.74143 17.3596 5.70122 16.9322C4.66101 16.5049 3.71593 15.8754 2.92074 15.0802C2.12556 14.285 1.49606 13.34 1.06874 12.2998C0.641413 11.2595 0.424748 10.1449 0.431296 9.02033C0.437845 7.89578 0.667477 6.78371 1.10689 5.74854C1.5463 4.71338 2.18676 3.7757 2.99115 2.98982C4.5914 1.42642 6.7435 0.556941 8.98065 0.569969C11.2178 0.582996 13.3596 1.47748 14.9416 3.05942C16.5235 4.64135 17.418 6.78317 17.431 9.02033C17.444 11.2575 16.5746 13.4096 15.0112 15.0098V15.0098Z"
          stroke="var(--light-background)"
        />
        <path
          d="M7.73891 6.8C7.73891 7.794 7.17391 8.6 6.47891 8.6C5.78191 8.6 5.21691 7.794 5.21691 6.8C5.21691 5.806 5.78191 5 6.47791 5C7.17391 5 7.73791 5.806 7.73791 6.8H7.73891ZM12.7819 6.8C12.7819 7.794 12.2169 8.6 11.5219 8.6C10.8249 8.6 10.2599 7.794 10.2599 6.8C10.2599 5.806 10.8249 5 11.5209 5C12.2169 5 12.7809 5.806 12.7809 6.8H12.7819ZM5.25291 11.07C5.36884 11.0042 5.50596 10.9865 5.6348 11.0207C5.76363 11.055 5.8739 11.1384 5.94191 11.253C6.25191 11.784 6.69691 12.226 7.23391 12.533C7.77176 12.8402 8.38053 13.0015 8.99991 13.001C9.61991 13.001 10.2289 12.84 10.7659 12.533C11.3016 12.2273 11.7472 11.7858 12.0579 11.253C12.0909 11.1954 12.1349 11.1448 12.1875 11.1042C12.2401 11.0636 12.3001 11.0339 12.3642 11.0166C12.4284 10.9993 12.4953 10.9948 12.5611 11.0035C12.627 11.0121 12.6904 11.0337 12.7479 11.067C12.8339 11.1166 12.9033 11.1906 12.9474 11.2795C12.9916 11.3685 13.0084 11.4685 12.9959 11.567C12.9867 11.6324 12.9646 11.6953 12.9309 11.752C12.5314 12.437 11.9585 13.0047 11.2699 13.398C10.5786 13.793 9.7961 14.0005 8.99991 14C8.20372 14.0005 7.42122 13.793 6.72991 13.398C6.04093 13.0049 5.4677 12.4371 5.06791 11.752C5.03465 11.6952 5.01304 11.6324 5.00434 11.5672C4.99565 11.502 5.00004 11.4357 5.01726 11.3722C5.03449 11.3087 5.0642 11.2492 5.10466 11.1974C5.14512 11.1455 5.19552 11.1022 5.25291 11.07Z"
          fill="var(--light-background)"
        />
        <path d="M12.5 11H5.5L8 13.5L11 13L12.5 11Z" fill="var(--light-background)" />
      </g>
      <defs>
        <clipPath id="clip0_149_8182">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
