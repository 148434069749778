import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../layout/layout";
import ContactUs from "../master/contactus/contactus";
import Faq from "../master/faq/faq";
import FreeCoin from "../master/freecoin/freecoin";
import HowitWork from "../master/howitwork/howitwork";
import "./home.css";
// import PropTypes from "prop-types";
import HomeGameGrid from "./homegamegrid";

function Home() {
  return (
    <Layout>
      <div className="home-page">
        <Container>
          <HomeGameGrid />
          <ContactUs />
          <FreeCoin />
          <HowitWork />
          <Faq />
        </Container>
      </div>
    </Layout>
  );
}

export default Home;
