import React, { useState } from "react";
import PropsTypes from "prop-types";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import { gameTypejson, gameRoundTimeout, gameTime } from "./utlis/jsonFiles";
import SelectDropDown from "./utlis/select-dropdown";
import "./LudoPage.css";

function LudoPage({ handleClose, show }) {
  const [data, setData] = useState({
    gameType: { label: "Movable timer", value: "Movable timer" },
    gameRoundTimeout: { label: "15 seconds", value: 15 },
    gameTime: { label: "5 minutes", value: 5 },
  });

  const handlegameType = (select) => {
    setData((prev) => ({ ...prev, gameType: select }));
  };

  const handlegameRound = (select) => {
    setData((prev) => ({ ...prev, gameRoundTimeout: select }));
  };

  const handlegameTime = (select) => {
    setData((prev) => ({ ...prev, gameTime: select }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("this is the handle Submit");
    const payload = data;
    await axios.post(
      "http://localhost:3001/api/createGame",
      payload
    );
    // console.log("this is the response", response);
  };
  return (
    <Modal
      className="modalContactUs-wrapper"
      show={show}
      onHide={handleClose}
      centered
    >
      <div className="modalClose-btn" onClick={handleClose} role="presentation">
        <CloseIcon />
      </div>
      <Modal.Body>
        <div className="modalContactUs">
          <div className="ludoHeaderModal">
            <Form onSubmit={handleSubmit}>
              <div className="ludoGameTitle">
                <h2>Ludo</h2>
              </div>
              <Form.Group className="modal-select">
                <Form.Label>Select a Game </Form.Label>
                <SelectDropDown
                  options={gameTypejson}
                  value={data.gameType}
                  onChange={handlegameType}
                />
              </Form.Group>
              <Form.Group className="modal-select">
                <Form.Label>Game Round Timeout </Form.Label>
                <SelectDropDown
                  options={gameRoundTimeout}
                  value={data.gameRoundTimeout}
                  onChange={handlegameRound}
                />
              </Form.Group>
              <Form.Group className="modal-select">
                <Form.Label>Game Time </Form.Label>
                <SelectDropDown
                  options={gameTime}
                  value={data.gameTime}
                  onChange={handlegameTime}
                />
              </Form.Group>

              <div className="gamePageBtn">
                <Button className="cancelBtn" onClick={handleClose}>
                  Cancel
                </Button>
                <Button className="createBtn" type="submit">
                  Create Game
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
LudoPage.propTypes = {
  handleClose: PropsTypes.func.isRequired,
  show: PropsTypes.bool.isRequired
}
export default LudoPage;

function CloseIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.625 2L2 16.625M2 2L16.625 16.625"
        stroke="white"
        strokeWidth="3.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
