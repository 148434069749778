import { Button, Form, Modal } from "react-bootstrap";
import PropsTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import sms from "../asset/image/LoginBackground/sms.svg";
import modalGameLogo from "../asset/image/contactus/game.svg";
import "./ContactUsModal.css";

function ContactUsModal({ handleClose, show }) {
  const gameOptions = [
    { value: "Crash", label: "Crash", color: "#fff" },
    { value: "Plinko", label: "Plinko", color: "#fff" },
    { value: "classNameic Dice", label: "classNameic Dice", color: "#fff" },
    { value: "Hilo", label: "Hilo", color: "#fff" },
    { value: "Ultimate Dice", label: "Ultimate Dice", color: "#fff" },
    { value: "Keno", label: "Keno", color: "#fff" },
    { value: "Mine", label: "Mine", color: "#fff" },
    { value: "Roulette", label: "Roulette", color: "#fff" },
  ];

  const styles = {
    option: (provided) => ({
      ...provided,
      background: "var(--input-color)",
      color: "#fff",
      fontWeight: "400",
      fontSize: "var(--linkfont)",
      padding: "12px",
      lineHeight: "var(--linkfont)",
      cursor: "pointer",
      border: "none",
      ":hover": {
        background: "var(--primary-bg)",
      },
    }),
    input: (base) => ({
      ...base,
      color: "#fff",
      paddingLeft: "36px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      paddingLeft: "36px",
    }),

    control: (base) => ({
      ...base,
      background: "var(--input-color)",
      border: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: "36px",
    }),
    container: (base) => ({
      ...base,
      border: "none",
      boxShadow: "none",
      zIndex: "10",
    }),
    menu: (provided) => ({
      ...provided,
      padding: "0px",
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "8px",
        background: "var(--input-color)",
      },
      "::-webkit-scrollbar-thumb": {
        background: "var(--primary-bg)",
      },
    }),
  };

  return (
    <Modal
      className="modalContactUs-wrapper"
      show={show}
      onHide={handleClose}
      centered
    >
      <div className="modalClose-btn" onClick={handleClose} role="presentation">
        <CloseIcon />
      </div>
      <Modal.Body>
        <div className="modalContactUs">
          <Form>
            <h4>Enquire Now</h4>
            <div className="name">
              <Form.Group className="reg-name">
                <Form.Label>First Name </Form.Label>
                <Form.Control type="name" placeholder="Zafar" />
              </Form.Group>
              <Form.Group className="reg-name">
                <Form.Label>Last Name </Form.Label>
                <Form.Control type="name" placeholder="Khan" />
              </Form.Group>
            </div>
            <Form.Group className="modal-mail">
              <Form.Label>Email </Form.Label>
              <img src={sms} alt="" />
              <Form.Control type="email" placeholder="example@mail.com" />
            </Form.Group>
            <Form.Group className="reg-mail">
              <Form.Label>Phone Number </Form.Label>
              <PhoneInput
                placeholder="Enter phone number"
                value="+91"
                searchPlaceholder=""
                className="modalContactUs-phone"
              />
            </Form.Group>
            <Form.Group className="modal-select">
              <Form.Label>Game </Form.Label>
              <img src={modalGameLogo} alt="" />
              <Select
                defaultValue="- Select Game -"
                label="- Select Game -"
                placeholder="- Select Game -"
                options={gameOptions}
                styles={styles}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Describe your case</Form.Label>
              <Form.Control
                className="modalTextArea"
                as="textarea"
                rows={5}
                placeholder="Your messege here..."
              />
            </Form.Group>
            <Button variant="primary" className="submitBtn" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
ContactUsModal.propTypes = {
  handleClose: PropsTypes.func.isRequired,
  show: PropsTypes.bool.isRequired,
};
export default ContactUsModal;

function CloseIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.625 2L2 16.625M2 2L16.625 16.625"
        stroke="white"
        strokeWidth="3.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
