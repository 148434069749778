/* eslint-disable no-console */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import LoginBackground from "../global/LoginRegisterHome/LoginBackground";
import google from "../asset/image/LoginBackground/google.svg";
// import key from "../asset/image/LoginBackground/key.svg";
import logo from "../asset/image/header/logo.png";
// import userImg from "../asset/image/LoginBackground/user.svg";
import "./Login.css";
import { emailRegex, passRegex } from "../../utils/authValidation";
import { loginApi } from "../../utils/userApi";

function Login() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({ mode: "onBlur" });

  const handleLogin = async (values) => {
    try {
      const { status, errMessage, tokens } = await loginApi(values);

      if (status === 200) {
        localStorage.setItem("loginToken", tokens?.access?.token);
        reset();
        navigate("/");
      } else {
        setError(
          "email",
          { message: errMessage, type: "required" },
          { shouldFocus: true }
        );
        setError(
          "password",
          { message: errMessage, type: "required" },
          { shouldFocus: true }
        );
      }
    } catch (e) {
      console.log("hii this is e", e);
    }
  };

  return (
    <div className="login-page">
      <Link to="/">
        <img className="logo" src={logo} alt="logo" />
      </Link>
      <LoginBackground />
      <div className="login-content">
        <div className="login-form">
          <Form onSubmit={handleSubmit(handleLogin)}>
            <h4> Log In</h4>
            <p> Nice to see you again</p>
            <Form.Group className="login-name" controlId="fiormBasicEmal">
              <Form.Label>
                Username
                {" "}
                <span>*</span>
              </Form.Label>
              {/* <img src={userImg} alt="" /> */}
              <Userimg />
              <Form.Control
                type="text"
                // name="email"
                placeholder="example@mail.com"
                {...register("email", {
                  required: "email is required",
                  pattern: {
                    value: emailRegex,
                    message: "please enter valid email",
                  },
                })}
                className={errors.email ? "error-input" : ""}
              />
              {errors.email && <span>{errors.email.message}</span>}
            </Form.Group>
            <Form.Group className="login-name" controlId="fiormBasicEmal">
              <Form.Label>
                Password
                {" "}
                <span>*</span>
              </Form.Label>
              {/* <img src={key} alt="" /> */}
              <Keyfunc />
              <Form.Control
                type="password"
                // name="password"
                autoComplete="off"
                placeholder="••••••••"
                {...register("password", {
                  required: "Enter Valid Password",
                  pattern: {
                    value: passRegex,
                    message:
                      "A minimum 8 characters password contains a combination of uppercase and lowercase letter and number are required.",
                  },
                })}
                className={errors.password ? "error-input" : ""}
              />
              {errors.password && <span>{errors.password.message}</span>}
            </Form.Group>
            <div className="toggle-Link">
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Remember me"
              />
              <Link to="/forgotPasswd">Forgot password?</Link>
            </div>

            <Button variant="primary" className="loginBtn" type="submit">
              Sign In
            </Button>

            <div className="login-line">
              <hr />
              Or
              {" "}
              <hr />
            </div>

            <Button variant="primary" className="regBtn" type="button">
              <img src={google} alt="" />
              Or sign in with Google
            </Button>

            <div className="notHav-acc">
              Dont have an account?
              <Link to="/register">Sign up now</Link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;

export function Userimg() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M8.00008 8.74967C9.84103 8.74967 11.3334 7.25729 11.3334 5.41634C11.3334 3.57539 9.84103 2.08301 8.00008 2.08301C6.15913 2.08301 4.66675 3.57539 4.66675 5.41634C4.66675 7.25729 6.15913 8.74967 8.00008 8.74967Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7268 15.4167C13.7268 12.8367 11.1601 10.75 8.0001 10.75C4.8401 10.75 2.27344 12.8367 2.27344 15.4167"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
export function Keyfunc() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M10 5.41672C10.3536 5.41672 10.6928 5.55719 10.9428 5.80724C11.1929 6.05729 11.3333 6.39643 11.3333 6.75005M14 6.75005C14.0002 7.37483 13.854 7.99097 13.5732 8.54908C13.2924 9.1072 12.8847 9.59177 12.3829 9.96397C11.8811 10.3362 11.2991 10.5856 10.6835 10.6924C10.0679 10.7991 9.43584 10.7602 8.838 10.5787L7.33333 12.0834H6V13.4167H4.66667V14.7501H2.66667C2.48986 14.7501 2.32029 14.6798 2.19526 14.5548C2.07024 14.4298 2 14.2602 2 14.0834V12.3594C2.00004 12.1826 2.0703 12.013 2.19533 11.8881L6.17133 7.91205C6.00497 7.36206 5.95904 6.78268 6.0367 6.21335C6.11435 5.64402 6.31375 5.0981 6.62133 4.61275C6.92891 4.1274 7.33744 3.71401 7.81913 3.40073C8.30082 3.08745 8.84435 2.88162 9.41272 2.79725C9.9811 2.71289 10.561 2.75197 11.1129 2.91184C11.6648 3.07171 12.1758 3.34861 12.6111 3.72369C13.0464 4.09878 13.3958 4.56324 13.6354 5.08548C13.8751 5.60771 13.9994 6.17545 14 6.75005Z"
          stroke="#EEEEEE"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
