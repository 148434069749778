import React from "react";
import pngwing from "../../asset/image/LoginBackground/pngwing.svg"

import "./LoginBackground.css"

function LoginBackground() {
  return (
    <div className="loginHome-img">
      <img src={pngwing} alt="Login Page Background Img" />
    </div>
  )
}

export default LoginBackground
