import React from "react";
import { Form, Button } from "react-bootstrap";
import Setting from "../Setting";
import CopyCode from "../../asset/image/settingIcon/copy.svg";
import qr from "../../asset/image/settingIcon/qr.svg";
import sms from "../../asset/image/LoginBackground/sms.svg";

import "./SecuritySetting.css";

function SecuritySetting() {
  return (
    <Setting>
      <div className="security-page">
        <div className="securityPage-tile">
          <h4>Two Factor</h4>
          <p>
            To keep your account extra secure leave a two factor authentication
            enabled.
          </p>
        </div>
        <div className="security-codecopy">
          <p>Copy this code to your authenticator app</p>
          <div className="securitycodeImg">
            <span className="copycode">
              PBKHAR26KRKHOLB6I5ZDEZJOIITCCJSXKUWHEg
            </span>
            <img src={CopyCode} alt="CopyCode" />
          </div>
        </div>
        <div className="security-qr">
          <p>Dont let anyone see this!</p>
          <img src={qr} alt="qr" />
        </div>
        <div className="security-twoFactorCode">
          <Form.Group className="security-mail">
            <Form.Label>
              Email
              {" "}
              <span>*</span>
            </Form.Label>
            <img src={sms} alt="sms" />
            <Form.Control type="email" placeholder="example@mail.com" />
          </Form.Group>
        </div>
        <Button className="security-pageBtn">Reverify with Google</Button>
      </div>
    </Setting>
  );
}

export default SecuritySetting;
