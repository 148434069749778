import React, { useState } from "react";
import PropsTypes from "prop-types";
import Footer from "../footer/footer";
import Header from "../header/header";
import MiniSideBar from "./minisidebar";
import SideBar from "./sidebar";
import "./layout.css";

function Layout({ children }) {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const handleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  return (
    <div className={`wrapper ${sidebarToggle ? "sidebar-expand" : ""} `}>
      <SideBar sidebarToggle={sidebarToggle} handleSidebar={handleSidebar} />
      <MiniSideBar />
      <div className="content-wrapper">
        <Header sidebarToggle={sidebarToggle} />
        <div className="content">{children}</div>
        <Footer />
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropsTypes.element.isRequired,
};

export default Layout;
