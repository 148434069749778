import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import user from "../../asset/image/header/user-icon.svg";
import "./iconbutton.css";

function IconButton() {
  return (
    <div className="skill-dark-button">
      <Link to="/register" className="LinkBtn">
        <Button>
          <div className="skill-icon">
            <img src={user} alt="icon" />
          </div>
          <h5>Register</h5>
        </Button>
      </Link>
    </div>
  );
}
export default IconButton;
