/* eslint-disable import/no-unresolved */
import React from "react";
// import Layout from "../../layout/layout";
import { Tabs, Tab } from "react-bootstrap";
import ManualGame from "./manualgame";
import AutomatedGame from "./automatedgame";
import DiceHistory from "./history";
import PreviousResult from "./previousresult";
import "./dice.css";
import Layout from "../../layout/layout";
// import "../responsive.css";

function Dice() {
  return (
    <Layout>
      <div className="dice">
        <div className="container">
          <div className="dice-tab">
            <Tabs defaultActiveKey="manual" id="uncontrolled-tab-example">
              <Tab eventKey="manual" title="Manual">
                <ManualGame />
              </Tab>
              <Tab eventKey="automated" title="Automated">
                <AutomatedGame />
              </Tab>
            </Tabs>
          </div>
          <PreviousResult />
          <DiceHistory />
        </div>
      </div>
    </Layout>
  );
}
export default Dice;
