/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */

import axios from "axios";

export const registerApi = async (payload) => {
  const obj = {
    status: "",
    errMessage: "",
    tokens: ""
  }
  try {
    const { data } = await axios.post("http://localhost:3001/v1/auth/register", payload);
    obj.status = data?.status
    obj.tokens = data?.tokens
  } catch (e) {
    obj.status = e?.response?.data?.code
    obj.errMessage = e?.response?.data?.message
  }

  return obj
}

export const loginApi = async (payload) => {
  const obj = {
    status: "",
    errMessage: "",
    tokens: ""
  }
  try {
    const response = await axios.post("http://localhost:3001/v1/auth/login", payload);
    obj.status = response?.status
    obj.tokens = response?.data?.tokens
  } catch (e) {
    obj.status = e?.response?.data?.code
    obj.errMessage = e?.response?.data?.message
  }
  return obj
}

export const forgetPasswordApi = async (payload) => {
  const obj = {
    status: "",
    errMessage: ""
  }
  try {
    const response = await axios.post("http://localhost:3001/v1/auth/forgot-password", payload);
    obj.status = response?.status
  } catch (e) {
    obj.status = e?.response?.data?.code
    obj.errMessage = e?.response?.data?.message
  }
  return obj
}

export const ResetPasswordApi = async (payload) => {
  const obj = {
    status: "",
    errMessage: ""
  }
  try {
    const response = await axios.post("http://localhost:3001/v1/auth/reset-password", payload);
    obj.status = response?.status
  } catch (e) {
    obj.status = e?.response?.data?.code
    obj.errMessage = e?.response?.data?.message
  }
  return obj
}
