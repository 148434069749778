import React from "react";
import PropsTypes from "prop-types";
import { Button } from "react-bootstrap";
import "./button.css";

function ColorButton({ btnName, handleShow }) {
  return (
    <div className="skill-color-button">
      <Button onClick={handleShow}>{btnName}</Button>
    </div>
  );
}

ColorButton.propTypes = {
  btnName: PropsTypes.string.isRequired,
  handleShow: PropsTypes.func.isRequired
}
export default ColorButton;
