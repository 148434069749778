import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PropsTypes from "prop-types";
import Bulb from "../asset/image/spinner/wheelBulb.png";
import spinTxt from "../asset/image/spinner/txtSpin.png";
import "./wheel.css";

function SpinWheel({ items, onSelectItem, handleClose, show }) {
  const [selectItem, setselectItem] = useState(null);

  //   const spinning = selectedItem !== null ? "spinning" : "";

  const select = () => {
    if (selectItem === null) {
      const selectedItem = Math.floor(Math.random() * items.length);
      if (onSelectItem) {
        onSelectItem(selectedItem);
      }
      setselectItem(selectedItem);
    } else {
      setselectItem(null);
      setTimeout(selectItem, 500);
    }
  };

  const wheelVars = {
    "--nb-item": items.length,
    "--selected-item": selectItem,
  };

  const spinning = selectItem !== null ? "spinning" : "";
  return (
    <Modal className="spin-wrapper" show={show} onHide={handleClose} centered>
      <div
        className="modal-close-btn"
        onClick={handleClose}
        role="presentation"
      >
        <CloseIcon />
      </div>
      <Modal.Body>
        <div className="wheel-container">
          <div className={`wheel ${spinning}`} style={wheelVars}>
            <div className="wheel-bulb">
              <img src={Bulb} alt="" />
            </div>
            {items.map((item, index) => (
              <div
                className="wheel-item"
                key={`item-${index + 1}`}
                style={{ "--item-nb": index }}
              >
                {item}
              </div>
            ))}
          </div>
          <img className="spinTxt" src={spinTxt} alt="" />
        </div>
        <Button onClick={select}>Spin</Button>
      </Modal.Body>
    </Modal>
  );
}

SpinWheel.propTypes = {
  items: PropsTypes.array.isRequired,
  onSelectItem: PropsTypes.func.isRequired,
  handleClose: PropsTypes.func.isRequired,
  show: PropsTypes.bool.isRequired
}

export default SpinWheel;

function CloseIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.625 2L2 16.625M2 2L16.625 16.625"
        stroke="white"
        strokeWidth="3.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
