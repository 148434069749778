import React from "react"
import Policies from "../PoliciesMenu/Policies"

function Termsncondition() {
  return (
    <Policies>
      <div className="privacy-page">
        <h4> Terms & Conditions</h4>
        <div className="privacyPara">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Adipisci ipsum blanditiis reiciendis cum libero delectus sint.
          Dolor dolores tempore nulla.
        </div>
        <div className="privacyPara">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Tempore aperiam asperiores necessitatibus amet dolore, libero explicabo nobis.
          Odit ipsa commodi pariatur iure recusandae porro, aperiam explicabo
          sequi vel molestias esse a nemo alias, perspiciatis tenetur.
          Nisi illo vero repellat quae mollitia eveniet quo consequuntur
          accusamus magni debitis assumenda porro maxime dolor,
          illum officiis incidunt molestiae reiciendis accusantium nihil odit.
          Nam, voluptates magni. Dignissimos facere officia quas dolore magni,
          repellendus recusandae tempora numquam ipsa expedita consectetur aliquam
          beatae excepturi suscipit. Optio eaque harum laudantium. Atque praesentium
          quam consequatur vero! Dolores laborum expedita, vero tenetur unde voluptates non.
          Quam qui, maxime harum debitis pariatur odit blanditiis et doloremque dignissimos
          fuga quidem. Corporis modi illo commodi natus itaque nisi cumque iusto neque,
          sed, enim, praesentium nesciunt corrupti dolore cum atque consectetur velit facere.
          Modi id tempore enim recusandae
        </div>
        <div className="privacyPara">
          <h4>Information Collection and Use</h4>
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Sapiente beatae temporibus vero iusto doloribus maxime explicabo,
          amet autem unde aperiam in harum, odit vel rem blanditiis sit animi
          facilis repellendus mollitia qui eligendi. Saepe debitis odit sunt
          dolorem modi autem placeat quae nostrum, iure nisi? Laboriosam minus
          et praesentium nobis impedit maxime voluptate id vel adipisci magnam
          quisquam cumque sapiente inventore temporibus, voluptatum consequuntur
          placeat doloribus odio eius, fugiat accusamus culpa. Alias, sequi molestias
          perferendis error accusantium doloremque libero. Doloremque.
        </div>
        <div className="privacyPara">
          <h4>Service Providers</h4>
          <ul className="privacyList">
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto, accusantium.</li>
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto, accusantium.</li>
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto, accusantium.</li>
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto, accusantium.</li>
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto, accusantium.</li>
          </ul>
        </div>
        <div className="privacyPara">
          <h4> Contact Us</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt,
            praesentium et rem eaque reiciendis quasi alias, tempora repellat earum
            {" "}

          </p>
        </div>

      </div>
    </Policies>
  )
}

export default Termsncondition
