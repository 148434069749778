/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import doller from "../../../assets/images/game/doller.svg";
import mines from "../../../assets/images/mines/mines.svg";
import whitegem from "../../../assets/images/mines/whitegem.svg";
import redgem from "../../../assets/images/header/gem.png";
// import xicon from '../../../assets/images/mines/x.svg';

function AutomatedTab() {
  const [gameActive, setGameActive] = useState(false);
  const [betSize, setBetSize] = useState("");
  // const [numberOfMines, setNumberOfMines] = useState("");
  // const [currentProfit, setCurrentProfit] = useState(0.00);

  const updateBetSizeAmount = (event) => {
    const inputValue = parseFloat(event.target.value);
    if (inputValue > 1000000 || inputValue < 0) { return; }

    const roundedBetSize = Math.round((inputValue + Number.EPSILON) * 100) / 100;
    setBetSize(roundedBetSize);
  }

  const updateBetSize = (updateType) => {
    let newBetSize = 0;

    // eslint-disable-next-line eqeqeq
    if (updateType == "/2") newBetSize = betSize / 2;
    else if (updateType == "X2") newBetSize = betSize * 2;
    else if (updateType == "Max") newBetSize = 69;

    const roundedBetSize = Math.round((newBetSize + Number.EPSILON) * 100) / 100;
    if (roundedBetSize > 100000) return;

    setBetSize(roundedBetSize);
  }

  return (
    <div className="manual-tab automated-tab">
      <Form>
        <div className="mines-bet-box">

          <Form.Group>
            <Form.Label>Bet Amount</Form.Label>
            <div className="bet-input pdd-200">
              <img src={doller} alt="" />

              {gameActive
                ? <Form.Control disabled type="number" placeholder="0.00" />
                : <Form.Control onChange={updateBetSizeAmount} value={betSize} type="number" placeholder="0.00" />}

              <div className="bet-input-btn">
                {gameActive
                  ? <>
                    <Button disabled onClick={() => updateBetSize("/2")}>1/2</Button>
                    <Button disabled onClick={() => updateBetSize("X2")}>2x</Button>
                    <Button disabled onClick={() => updateBetSize("Max")}>Max</Button>
                    </>
                  : <>
                    <Button onClick={() => updateBetSize("/2")}>1/2</Button>
                    <Button onClick={() => updateBetSize("X2")}>2x</Button>
                    <Button onClick={() => updateBetSize("Max")}>Max</Button>
                    </>}
              </div>
            </div>
          </Form.Group>

          <div className="automated-bet-half">
            <Form.Group>
              <Form.Label>Mines</Form.Label>
              <div className="bet-input">
                <img src={mines} alt="" />
                <Form.Control type="number" placeholder="20" />
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Bet</Form.Label>
              <div className="bet-input">
                <img src={whitegem} alt="" />
                <Form.Control type="number" placeholder="3" />
              </div>
            </Form.Group>
          </div>

          <div className="on-win-section">
            <Form.Group>
              <Form.Label>On Win</Form.Label>
              <div className="bet-input">
                <div className="left-win-section">
                  <p>Reset</p>
                  <p>Increase By</p>
                </div>
                <Form.Control type="number" placeholder="50.00" />
                <span>%</span>
              </div>
            </Form.Group>
          </div>

          <Form.Group>
            <Form.Label>Stop on Profit</Form.Label>
            <div className="bet-input">
              <img src={redgem} alt="" />
              <Form.Control type="number" placeholder="0.00" />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Stop on Loss</Form.Label>
            <div className="bet-input">
              <img src={redgem} alt="" />
              <Form.Control type="number" placeholder="0.00" />
            </div>
          </Form.Group>
        </div>
        <div className="roll-dice">
          {gameActive
            ? <Button className="stop-auto-bet-btn" onClick={() => setGameActive(!gameActive)}><span>Stop Autobet</span></Button>
            : <Button onClick={() => setGameActive(!gameActive)}><span>Start Autobet</span></Button>}

        </div>
      </Form>
      <div className="change-seed">
        <Button>Change Seeds</Button>
      </div>
    </div>
  );
}

export default AutomatedTab;
