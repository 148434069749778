import React from "react"
import { Form, Button } from "react-bootstrap"
import Setting from "../Setting"

import "./SettingPrivacy.css"

function SettingPrivacy() {
  return (
    <Setting>
      <div className="settingPrivacy-page">
        <div className="settingPrivacy-privacyContainer">
          <h4>Privacy</h4>
          <h6>
            User privacy is one of the core values of Moonbet.
            These settings allow you to be completely anonymous
            from the rest of the players.

          </h6>
        </div>
        <div className="settingPrivacy-togglers">
          <div className="toggle-content">
            <Form.Check
              type="switch"
              id="custom-switch"
            />
            <div className="toggle-contentText">
              <h6>Enable Ghost Mode</h6>
              <p>Your bets wont appear in public bet feed and bet preview</p>
            </div>
          </div>
          <div className="toggle-content">
            <Form.Check
              type="switch"
              id="custom-switch"
            />
            <div className="toggle-contentText">
              <h6>Hide all your statistics</h6>
              <p>Other users wont be able to view your wins, loses and wagered statistics</p>
            </div>
          </div>
          <div className="toggle-content">
            <Form.Check
              type="switch"
              id="custom-switch"
            />
            <div className="toggle-contentText">
              <h6>Hide all your race statistics</h6>
              <p>Other users wont be able to view your race statistics</p>
            </div>
          </div>
        </div>
        <div className="settingPrivacy-saveBtn">
          <Button className="generalSet-btn">Save</Button>
          <p>Please allow up to 30 seconds for update to take effect.</p>
        </div>
        <div className="settingPrivacy-community">
          <h4>Community</h4>
          <div className="toggle-content">
            <Form.Check
              type="switch"
              id="custom-switch"
            />
            <div className="toggle-contentText">
              <h6>Exclude from rain</h6>
              <p>Prevents you from receiving a rain in chat</p>
            </div>
          </div>
        </div>
        <div className="settingPrivacy-saveBtn">
          <Button className="generalSet-btn">Save</Button>
          <p>Please allow up to 30 seconds for update to take effect.</p>
        </div>
        <div className="settingPrivacy-selfExclusion">
          <h4>Self Exclusion</h4>
          <h6>
            Need a break from Moonbet? To start the automated
            self exclusion process, please click the button below
            to receive confirmation instructions via email.

          </h6>
        </div>
        <div className="settingPrivacy-saveBtn">
          <Button className="security-pageBtn">Request Self Exclusion</Button>
          <div className="Self-Exclusion">
            <p>Learn more about </p>
            <h6>Self Exclusion</h6>
          </div>
        </div>
      </div>
    </Setting>
  )
}

export default SettingPrivacy
