import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/home/home";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import ForgtPasswd from "./components/ForgtPasswd/ForgtPasswd";
import ResetPasswd from "./components/ForgtPasswd/ResetPasswd/ResetPasswd";
import FaqPage from "./components/FAQ/Faq";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Termsncondition from "./components/TermsNcondition/Termsncondition";
import Policies from "./components/PoliciesMenu/Policies";
import ContactUsModal from "./components/Modal/ContactUsModal";
import Setting from "./components/Settings/Setting";
import GeneralSetting from "./components/Settings/GeneralSetting/GeneralSetting";
import SecuritySetting from "./components/Settings/Security/SecuritySetting";
import SettingPrivacy from "./components/Settings/SettingPrivacy/SettingPrivacy";
import SessionSetting from "./components/Settings/SessionsSetting/SessionSetting";

import "./App.css";
// import "./App.scss";
// import "./style/theme.scss"
import Error404 from "./components/Error404/Error404";
import ContactUsPage from "./components/ContactUsPage/ContactUsPage";
import AllGames from "./components/AllGames/AllGames";
import HowToPlay from "./components/Howtoplay/howToPlay";
import Support from "./components/support/Support";
// import { Support } from "./components/layout/minisidebar";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./responsive.css"
import Dice from "./components/games/dice/dice";
import Tower from "./components/games/tower/tower";
import Roulette from "./components/games/roulette/roulette";
import Mines from "./components/games/mines/mines";

function App() {
  // const [isToggle, setIstoggle] = useState("dark");
  return (
    <div className="App">
      <div className="main-wrapper">
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotPasswd" element={<ForgtPasswd />} />
            <Route path="/resetPasswd" element={<ResetPasswd />} />
            <Route path="/faqPage" element={<FaqPage />} />
            <Route path="/contactUs" element={<ContactUsPage />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/termsncondtn" element={<Termsncondition />} />
            <Route path="/policies" element={<Policies />} />
            <Route path="/enquiry" element={<ContactUsModal />} />
            <Route path="/setting" element={<Setting />} />

            <Route path="/generalSetting" element={<GeneralSetting />} />
            <Route path="/securitysetting" element={<SecuritySetting />} />
            <Route path="/settingPrivacy" element={<SettingPrivacy />} />
            <Route path="/sessionSetting" element={<SessionSetting />} />

            <Route path="/howtoplay" element={<HowToPlay />} />
            <Route path="/support" element={<Support />} />
            <Route path="/dice" element={<Dice />} />
            <Route path="/tower" element={<Tower />} />
            <Route path="/roulette" element={<Roulette />} />
            <Route path="/mines" element={<Mines />} />

            <Route path="/error404" element={<Error404 />} />
            <Route path="/allGames" element={<AllGames />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
