/* eslint-disable max-len */
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import InputRange from "react-input-range";
// import doller from "../../../assets/images/game/doller.svg";
import refresh from "../../../assets/images/game/refresh.svg";
import rollimg from "../../../assets/images/game/roll.svg";
import cross from "../../../assets/images/game/cross.svg";
import percent from "../../../assets/images/game/percent.svg";
import "react-input-range/lib/css/index.css";

function ManualGame() {
  const [userInputOnSlider, setUserInputOnSlider] = useState(50);
  const [diceRollResult, setDiceRollResult] = useState(10.55);
  const [wonBet, setWonBet] = useState(true);
  const [betSize, setBetSize] = useState("");
  const [rollType, setRollType] = useState("under");
  const [multiplier, setMulitplier] = useState(2.0);
  const [winChance, setWinChance] = useState(50.0);
  const [payoutOnWin, setPayoutOnWin] = useState(0.0);

  const handleUpdateFields = (updateFrom, value) => {
    if (updateFrom === "slider") {
      if (rollType === "under") {
        if (value > 94) {
          setUserInputOnSlider(94.0);
          setWinChance(94.0);
          return;
        }
        if (value < 0.01) {
          setUserInputOnSlider(0.01);
          setWinChance(0.01);
          // value = 0.01;
        }
      } else {
        if (value < 6) {
          setUserInputOnSlider(6.0);
          setWinChance(94.0);
          return;
        }
        if (value > 99.99) {
          setUserInputOnSlider(99.99);
          setWinChance(99.99);
          // value = 99.99;
        }
      }

      const roundedValue = Math.round((value + Number.EPSILON) * 100) / 100;
      setUserInputOnSlider(roundedValue);

      if (rollType === "under") {
        const winChance1 = roundedValue;
        const roundedWinChance = Math.round((winChance1 + Number.EPSILON) * 100) / 100;
        setWinChance(roundedWinChance);
        const winChanceMulti = winChance1 / 0.01;
        const multi = 9500 / winChanceMulti;
        const roundedMulti = Math.round((multi + Number.EPSILON) * 100) / 100;
        setMulitplier(roundedMulti);

        const profit1 = betSize * multi;
        const roundedprofit1 = Math.round((profit1 + Number.EPSILON) * 100) / 100;
        setPayoutOnWin(roundedprofit1);
      } else {
        const winChance1 = 100 - roundedValue;
        const roundedWinChance = Math.round((winChance1 + Number.EPSILON) * 100) / 100;
        setWinChance(roundedWinChance);

        const winChanceMulti = winChance / 0.01;
        const multi = 9500 / winChanceMulti;
        const roundedMulti = Math.round((multi + Number.EPSILON) * 100) / 100;
        setMulitplier(roundedMulti);

        const profit1 = betSize * multi;
        const roundedprofit1 = Math.round((profit1 + Number.EPSILON) * 100) / 100;
        setPayoutOnWin(roundedprofit1);
      }
    } else if (updateFrom === "rollUnderOver") {
      const newValue = parseFloat(value);
      let roundedNewValue = Math.round((newValue + Number.EPSILON) * 100) / 100;
      if (roundedNewValue > 100 || roundedNewValue < 0) return;

      setUserInputOnSlider(roundedNewValue);
      if (rollType === "under") {
        if (roundedNewValue > 94) {
          roundedNewValue = 94;
          setUserInputOnSlider(roundedNewValue);
        }

        const winChance1 = roundedNewValue;
        setWinChance(winChance1);

        const winChanceMulti = winChance1 / 0.01;
        const multi = 9500 / winChanceMulti;
        const roundedMulti = Math.round((multi + Number.EPSILON) * 100) / 100;
        setMulitplier(roundedMulti);

        const profit1 = betSize * multi;
        const roundedprofit1 = Math.round((profit1 + Number.EPSILON) * 100) / 100;
        setPayoutOnWin(roundedprofit1);
      } else {
        if (roundedNewValue < 6) {
          roundedNewValue = 6;
          setUserInputOnSlider(roundedNewValue);
        }

        const winChance1 = 100 - roundedNewValue;
        const roundedWinChance = Math.round((winChance1 + Number.EPSILON) * 100) / 100;
        setWinChance(roundedWinChance);

        const winChanceMulti = roundedWinChance / 0.01;
        const multi = 9500 / winChanceMulti;
        const roundedMulti = Math.round((multi + Number.EPSILON) * 100) / 100;
        setMulitplier(roundedMulti);

        const profit1 = betSize * multi;
        const roundedprofit1 = Math.round((profit1 + Number.EPSILON) * 100) / 100;
        setPayoutOnWin(roundedprofit1);
      }
    } else if (updateFrom === "multi") {
      let newMulti = parseFloat(value);
      if (newMulti > 9500) {
        newMulti = 9500;
        setMulitplier(9500);
      } else if (newMulti < 1) {
        newMulti = 1;
        setMulitplier(1);
      }

      const profit1 = betSize * newMulti;
      const roundedprofit1 = Math.round((profit1 + Number.EPSILON) * 100) / 100;
      setPayoutOnWin(roundedprofit1);

      const roundedNewMulti = Math.round((newMulti + Number.EPSILON) * 100) / 100;
      setMulitplier(roundedNewMulti);

      // let profit1 = betSize * roundedNewMulti;
      // let roundedprofit1 = Math.round((profit1 + Number.EPSILON) * 100) / 100;
      // setPayoutOnWin(roundedprofit1);

      const winChanceMulti = 9500 / roundedNewMulti;
      const winChance1 = winChanceMulti * 0.01;
      let roundedWinChance = Math.round((winChance1 + Number.EPSILON) * 100) / 100;
      if (roundedWinChance > 94) roundedWinChance = 94;

      setWinChance(roundedWinChance);

      if (rollType === "under") {
        setUserInputOnSlider(roundedWinChance);
      } else {
        const rollAbove = 100 - roundedWinChance;
        const roundedRollAbove = Math.round((rollAbove + Number.EPSILON) * 100) / 100;
        setUserInputOnSlider(roundedRollAbove);
      }
    } else if (updateFrom === "winChance") {
      const newWinChance = parseFloat(value);
      const roundedNewWinChance = Math.round((newWinChance + Number.EPSILON) * 100) / 100;

      if (roundedNewWinChance < 0.01 || roundedNewWinChance > 99.99) {
        setWinChance(50.0);
        return;
      }
      // if (roundedWinChance > 94) {
      //   roundedWinChance = 94;
      // }

      setWinChance(roundedNewWinChance);

      const winChanceMulti = roundedNewWinChance / 0.01;
      const multi = 9500 / winChanceMulti;
      const roundedMulti = Math.round((multi + Number.EPSILON) * 100) / 100;
      setMulitplier(roundedMulti);

      const profit1 = betSize * multi;
      const roundedprofit1 = Math.round((profit1 + Number.EPSILON) * 100) / 100;
      setPayoutOnWin(roundedprofit1);

      if (rollType === "under") {
        setUserInputOnSlider(roundedNewWinChance);
      } else {
        const roundedRollOver = Math.round((100 - roundedNewWinChance + Number.EPSILON) * 100) / 100;
        setUserInputOnSlider(roundedRollOver);
      }
    } else if (updateFrom === "payoutOnWin") {
      if (value <= betSize) {
        return;
      }

      setPayoutOnWin(value);

      const multi = value / betSize;
      const roundedMulti = Math.round((multi + Number.EPSILON) * 100) / 100;
      setMulitplier(roundedMulti);

      const winChanceMulti = 9500 / roundedMulti;
      const winChance1 = winChanceMulti * 0.01;
      const roundedWinChance = Math.round((winChance1 + Number.EPSILON) * 100) / 100;
      setWinChance(roundedWinChance);

      if (rollType === "under") {
        setUserInputOnSlider(roundedWinChance);
      } else {
        const rollAbove = 100 - roundedWinChance;
        const roundedRollAbove = Math.round((rollAbove + Number.EPSILON) * 100) / 100;
        setUserInputOnSlider(roundedRollAbove);
      }
    } else if (updateFrom === "betSize") {
      const payoutOnWin1 = value * multiplier;
      const roundedPayoutOnWin = Math.round((payoutOnWin1 + Number.EPSILON) * 100) / 100;
      setPayoutOnWin(roundedPayoutOnWin);
    }
  };

  const handleToggleUnderOver = () => {
    if (rollType === "under") {
      if (userInputOnSlider < 6) setUserInputOnSlider(6);

      const newWinChance = 100 - userInputOnSlider;
      const roundedNewSliderValue = Math.round((newWinChance + Number.EPSILON) * 100) / 100;

      setWinChance(roundedNewSliderValue);

      const winChanceMulti = roundedNewSliderValue / 0.01;
      const multi = 9500 / winChanceMulti;
      const roundedMulti = Math.round((multi + Number.EPSILON) * 100) / 100;
      setMulitplier(roundedMulti);

      const profit1 = betSize * multi;
      const roundedprofit1 = Math.round((profit1 + Number.EPSILON) * 100) / 100;
      setPayoutOnWin(roundedprofit1);

      setRollType("over");
    } else {
      if (userInputOnSlider > 94) setUserInputOnSlider(94);

      setWinChance(userInputOnSlider);

      const winChanceMulti = userInputOnSlider / 0.01;
      const multi = 9500 / winChanceMulti;
      const roundedMulti = Math.round((multi + Number.EPSILON) * 100) / 100;
      setMulitplier(roundedMulti);

      const profit1 = betSize * multi;
      const roundedprofit1 = Math.round((profit1 + Number.EPSILON) * 100) / 100;
      setPayoutOnWin(roundedprofit1);

      setRollType("under");
    }
  };

  const handleSetDiceRollResult = (sliderValue) => {
    const roundedValue = Math.round((sliderValue + Number.EPSILON) * 100) / 100;
    setDiceRollResult(roundedValue);
  };

  const handleEditBetSize = (event) => {
    const newBetSize = parseFloat(event.target.value);
    if (newBetSize > 1000000 || newBetSize < 0) return;

    const roundedBetSize = Math.round((newBetSize + Number.EPSILON) * 100) / 100;
    setBetSize(roundedBetSize);
    handleUpdateFields("betSize", roundedBetSize);
  };

  const handleUpdateBetSize = (updateType) => {
    let newBetSize = 0;

    if (updateType === "/2") newBetSize = betSize / 2;
    else if (updateType === "X2") newBetSize = betSize * 2;
    else if (updateType === "Max") newBetSize = 69;

    const roundedBetSize = Math.round((newBetSize + Number.EPSILON) * 100) / 100;
    if (roundedBetSize > 100000) return;

    setBetSize(roundedBetSize);
    handleUpdateFields("betSize", roundedBetSize);
  };

  const handleRollDice = () => {
    const random = Math.random() * 100;
    const roundedRoll = Math.round((random + Number.EPSILON) * 100) / 100;
    setDiceRollResult(roundedRoll);

    if (rollType === "under") {
      if (roundedRoll < userInputOnSlider) {
        setWonBet(true);
      } else {
        setWonBet(false);
      }
    } else if (roundedRoll > userInputOnSlider) {
      setWonBet(true);
    } else {
      setWonBet(false);
    }
  };

  return (
    <div className="manual-game">
      <Form>
        <div className="bet-box">
          <Form.Group className="bet-box-inp">
            <Form.Label>Bet Amount</Form.Label>
            <div className="bet-input pdd-200">
              {/* <img src={doller} alt="" /> */}
              <Dollar />
              <Form.Control
                value={betSize}
                onChange={handleEditBetSize}
                type="number"
                placeholder="0.00"
              />
              <div className="bet-input-btn">
                <Button onClick={() => handleUpdateBetSize("/2")}>1/2</Button>
                <Button onClick={() => handleUpdateBetSize("X2")}>2x</Button>
                <Button onClick={() => handleUpdateBetSize("Max")}>Max</Button>
              </div>
            </div>
          </Form.Group>

          <Form.Group className="bet-box-inp">
            <Form.Label>Payout On Win</Form.Label>
            <div className="bet-input">
              {/* <img src={doller} alt="" /> */}
              <Dollar />
              <Form.Control
                value={payoutOnWin}
                onChange={(event) =>
                  handleUpdateFields("payoutOnWin", event.target.value)}
                type="number"
                placeholder="0.00"
                step="0.01"
              />
            </div>
          </Form.Group>
        </div>

        <div className="payout-box">
          <Form.Group className="payout-box-inp">
            <Form.Label>
              Roll
              {rollType === "under" ? (
                <span className="roll-under-text"> Under</span>
              ) : (
                <span className="roll-over-text"> Over</span>
              )}
            </Form.Label>
            <div
              className={`bet-input roll-input ${
                rollType === "under" ? "apply" : ""
              }`}
            >
              <span
                onClick={handleToggleUnderOver}
                className="roll-under"
                role="presentation"
              >
                <img src={rollimg} alt="" />
              </span>
              <img src={refresh} alt="" />
              <Form.Control
                onChange={(event) =>
                  handleUpdateFields("rollUnderOver", event.target.value)}
                value={userInputOnSlider}
                type="number"
                placeholder="50.00"
                step="0.01"
              />
            </div>
          </Form.Group>

          <Form.Group className="payout-box-inp">
            <Form.Label>Multiplier</Form.Label>
            <div className="bet-input">
              <img src={cross} alt="" />
              <Form.Control
                value={multiplier}
                onChange={(event) =>
                  handleUpdateFields("multi", event.target.value)}
                type="number"
                placeholder="1.94"
                step="0.01"
              />
            </div>
          </Form.Group>

          <Form.Group className="payout-box-inp">
            <Form.Label>Win Chance</Form.Label>
            <div className="bet-input">
              <img src={percent} alt="" />
              <Form.Control
                value={winChance}
                onChange={(event) =>
                  handleUpdateFields("winChance", event.target.value)}
                type="number"
                placeholder="50.00"
                step="0.01"
              />
            </div>
          </Form.Group>
        </div>

        <div className="roll-dice">
          <Button onClick={handleRollDice}>
            <span>ROLL DICE</span>
          </Button>
        </div>
        <div
          className={`roll-slider dice-slider ${
            rollType === "under" ? "flips" : ""
          }`}
        >
          <div
            className={
              wonBet
                ? "result-dice result-dice-win"
                : "result-dice result-dice-lose"
            }
          >
            <InputRange
              className="result-slider"
              maxValue={100}
              minValue={0}
              step={0.01}
              value={diceRollResult}
              onChange={(value) => handleSetDiceRollResult(value)}
            />
          </div>

          <InputRange
            maxValue={100}
            minValue={0}
            value={userInputOnSlider}
            step={0.01}
            onChange={(value) => handleUpdateFields("slider", value)}
          />
        </div>
      </Form>
    </div>
  );
}

export default ManualGame;

export function Dollar() {
  return (
    <svg
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.296 9.984V8.688C7.296 8.048 6.992 7.504 6.624 7.296L3.2 5.312C3.088 5.248 3.04 5.104 3.04 4.928V4.624C3.04 4.304 3.216 4.144 3.536 4.144H4.816C5.2 4.144 5.472 4.224 5.776 4.352L6.992 4.864L7.168 3.072L6.096 2.608C5.728 2.448 5.328 2.368 5.12 2.368H5.088V0.464H3.072V2.368H2.992C1.872 2.368 0.928 3.264 0.928 4.384V5.504C0.928 6.144 1.232 6.704 1.6 6.912L4.992 8.88C5.104 8.944 5.168 9.088 5.168 9.264V9.744C5.168 10.064 4.976 10.224 4.656 10.224H3.232C2.848 10.224 2.56 10.144 2.256 10.016L1.056 9.504L0.88 11.296L1.936 11.76C2.304 11.92 2.704 12 3.024 12H3.072V14.064H5.088V12H5.248C6.368 12 7.296 11.104 7.296 9.984Z"
        fill="white"
      />
    </svg>
  );
}
