import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import PropsTypes from "prop-types";
import sidebtn from "../asset/image/sidebar/sidebar-btn.svg";
import logo from "../asset/image/header/logo.png";
import logodark from "../asset/image/header/skillPlayz-dark.png";
import DarkMode from "./darkmode";
import SocialMedia from "./socialmedia";
import "./layout.css";
import SpinWheel from "../spinWheel/spinWheel";
import { ThemeContext } from "../contexts/ThemeContext";

function SideBar({ handleSidebar }) {
  const places = [
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
    "1000",
    "1100",
    "1200",
    "1300",
    "1400",
    "1500",
    "1600",
  ];

  const [showSpinner, setShowSpinner] = useState(false);
  const handleClose = () => setShowSpinner(false);
  const handleShow = () => setShowSpinner(true);
  const mode = useContext(ThemeContext);
  // setIstoggle(mode.theme);
  // console.log(isToggle);
  return (
    <div className="sidebar-content">
      <div className="sidebar-logo">
        <Link to="/">
          <img
            src={mode.theme === "dark" ? logo : logodark}
            alt="logo"
            width="150px"
          />
        </Link>
        <div className="mini-sidebar-btn">
          <Button onClick={handleSidebar}>
            <img src={sidebtn} alt="icon" />
          </Button>
        </div>
      </div>
      <div className="sidebar-menu-list">
        <NavLink to="/">
          <Home />
          Home
        </NavLink>

        <NavLink to="/allGames">
          <Original />
          SG Originals
        </NavLink>

        <Button onClick={handleShow} className="nav-spin-btn">
          <FreeSpin />
          {" "}
          Free Spin
        </Button>

        <NavLink to="/">
          <Promotion />
          Promotion
        </NavLink>

        <NavLink to="/contactUs">
          <Contact />
          Contact
        </NavLink>

        <NavLink to="/howtoplay">
          <Play />
          How To Play
        </NavLink>

        <NavLink to="/faqPage">
          <Faq />
          FAQ
        </NavLink>

        <NavLink to="/support">
          <Support />
          Support
        </NavLink>
      </div>
      <DarkMode />

      <SocialMedia />
      <SpinWheel items={places} handleClose={handleClose} show={showSpinner} />
    </div>
  );
}
SideBar.propTypes = {
  handleSidebar: PropsTypes.func.isRequired,
};
export default SideBar;

export function Home() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5869 2.63L3.5444 5.78C2.8694 6.305 2.3219 7.4225 2.3219 8.27V13.8275C2.3219 15.5675 3.7394 16.9925 5.4794 16.9925H14.1644C15.9044 16.9925 17.3219 15.5675 17.3219 13.835V8.375C17.3219 7.4675 16.7144 6.305 15.9719 5.7875L11.3369 2.54C10.2869 1.805 8.5994 1.8425 7.5869 2.63Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.8219 13.9925V11.7425"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Original() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3219 6.89V3.485C17.3219 2.4275 16.8419 2 15.6494 2H12.6194C11.4269 2 10.9469 2.4275 10.9469 3.485V6.8825C10.9469 7.9475 11.4269 8.3675 12.6194 8.3675H15.6494C16.8419 8.375 17.3219 7.9475 17.3219 6.89Z"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3219 15.3275V12.2975C17.3219 11.105 16.8419 10.625 15.6494 10.625H12.6194C11.4269 10.625 10.9469 11.105 10.9469 12.2975V15.3275C10.9469 16.52 11.4269 17 12.6194 17H15.6494C16.8419 17 17.3219 16.52 17.3219 15.3275Z"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6969 6.89V3.485C8.6969 2.4275 8.2169 2 7.0244 2H3.9944C2.8019 2 2.3219 2.4275 2.3219 3.485V6.8825C2.3219 7.9475 2.8019 8.3675 3.9944 8.3675H7.0244C8.2169 8.375 8.6969 7.9475 8.6969 6.89Z"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6969 15.3275V12.2975C8.6969 11.105 8.2169 10.625 7.0244 10.625H3.9944C2.8019 10.625 2.3219 11.105 2.3219 12.2975V15.3275C2.3219 16.52 2.8019 17 3.9944 17H7.0244C8.2169 17 8.6969 16.52 8.6969 15.3275Z"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function FreeSpin() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4194 9.00502C11.7119 7.77502 10.6394 6.70247 9.40944 6.99497C8.83194 7.13747 8.35945 7.61 8.21695 8.18749C7.92445 9.41749 8.99694 10.49 10.2269 10.1975C10.8119 10.055 11.2844 9.58252 11.4194 9.00502Z"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3294 13.3925C15.6119 12.1925 16.4144 10.49 16.4144 8.59248C16.4144 4.94748 13.4594 2 9.82187 2C6.18437 2 3.22937 4.95498 3.22937 8.59248C3.22937 10.4975 4.03937 12.215 5.33687 13.415"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.82185 11.4125C6.13185 10.6775 5.70435 9.68748 5.70435 8.59248C5.70435 6.31998 7.54935 4.47499 9.82185 4.47499C12.0943 4.47499 13.9394 6.31998 13.9394 8.59248C13.9394 9.68748 13.5118 10.67 12.8218 11.4125"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.54694 12.995L7.46694 14.3375C6.61194 15.41 7.36944 16.9925 8.74194 16.9925H10.8944C12.2669 16.9925 13.0319 15.4025 12.1694 14.3375L11.0894 12.995C10.4444 12.1775 9.19944 12.1775 8.54694 12.995Z"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Promotion() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8219 6.5H6.8219V12.5H12.8219V6.5Z"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5719 17C5.8094 17 6.8219 15.9875 6.8219 14.75V12.5H4.5719C3.3344 12.5 2.3219 13.5125 2.3219 14.75C2.3219 15.9875 3.3344 17 4.5719 17Z"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5719 6.5H6.8219V4.25C6.8219 3.0125 5.8094 2 4.5719 2C3.3344 2 2.3219 3.0125 2.3219 4.25C2.3219 5.4875 3.3344 6.5 4.5719 6.5Z"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8219 6.5H15.0719C16.3094 6.5 17.3219 5.4875 17.3219 4.25C17.3219 3.0125 16.3094 2 15.0719 2C13.8344 2 12.8219 3.0125 12.8219 4.25V6.5Z"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0719 17C16.3094 17 17.3219 15.9875 17.3219 14.75C17.3219 13.5125 16.3094 12.5 15.0719 12.5H12.8219V14.75C12.8219 15.9875 13.8344 17 15.0719 17Z"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Contact() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2994 14.2475C17.2994 14.5175 17.2394 14.795 17.1119 15.065C16.9844 15.335 16.8194 15.59 16.6019 15.83C16.2344 16.235 15.8294 16.5275 15.3719 16.715C14.9219 16.9025 14.4344 17 13.9094 17C13.1444 17 12.3269 16.82 11.4644 16.4525C10.6019 16.085 9.7394 15.59 8.8844 14.9675C8.0219 14.3375 7.2044 13.64 6.4244 12.8675C5.6519 12.0875 4.9544 11.27 4.3319 10.415C3.7169 9.56 3.2219 8.705 2.8619 7.8575C2.5019 7.0025 2.3219 6.185 2.3219 5.405C2.3219 4.895 2.4119 4.4075 2.5919 3.9575C2.7719 3.5 3.0569 3.08 3.4544 2.705C3.9344 2.2325 4.4594 2 5.0144 2C5.2244 2 5.4344 2.045 5.6219 2.135C5.8169 2.225 5.9894 2.36 6.1244 2.555L7.8644 5.0075C7.9994 5.195 8.0969 5.3675 8.1644 5.5325C8.2319 5.69 8.2694 5.8475 8.2694 5.99C8.2694 6.17 8.2169 6.35 8.1119 6.5225C8.0144 6.695 7.8719 6.875 7.6919 7.055L7.1219 7.6475C7.0394 7.73 7.0019 7.8275 7.0019 7.9475C7.0019 8.0075 7.0094 8.06 7.0244 8.12C7.0469 8.18 7.0694 8.225 7.0844 8.27C7.2194 8.5175 7.4519 8.84 7.7819 9.23C8.1194 9.62 8.4794 10.0175 8.8694 10.415C9.2744 10.8125 9.6644 11.18 10.0619 11.5175C10.4519 11.8475 10.7744 12.0725 11.0294 12.2075C11.0669 12.2225 11.1119 12.245 11.1644 12.2675C11.2244 12.29 11.2844 12.2975 11.3519 12.2975C11.4794 12.2975 11.5769 12.2525 11.6594 12.17L12.2294 11.6075C12.4169 11.42 12.5969 11.2775 12.7694 11.1875C12.9419 11.0825 13.1144 11.03 13.3019 11.03C13.4444 11.03 13.5944 11.06 13.7594 11.1275C13.9244 11.195 14.0969 11.2925 14.2844 11.42L16.7669 13.1825C16.9619 13.3175 17.0969 13.475 17.1794 13.6625C17.2544 13.85 17.2994 14.0375 17.2994 14.2475Z"
        stroke="#EEEEEE"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export function Play() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3219 9.5C17.3219 10.4825 17.1344 11.4275 16.7819 12.29C16.2944 11.9525 15.7019 11.75 15.0719 11.75C14.3669 11.75 13.7144 11.9975 13.1969 12.41C12.5069 12.9575 12.0719 13.805 12.0719 14.75C12.0719 15.3125 12.2294 15.845 12.5069 16.295C12.5369 16.3475 12.5744 16.4 12.6194 16.4525C11.7569 16.805 10.8119 17 9.8219 17C5.6819 17 2.3219 13.64 2.3219 9.5C2.3219 5.36 5.6819 2 9.8219 2C13.9619 2 17.3219 5.36 17.3219 9.5Z"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.85693 9.50001V8.4575C7.85693 7.1675 8.77193 6.63499 9.88943 7.27999L10.7894 7.79749L11.6894 8.31499C12.8069 8.95999 12.8069 10.0175 11.6894 10.6625L10.7894 11.18L9.88943 11.6975C8.77193 12.3425 7.85693 11.8175 7.85693 10.52V9.50001Z"
        stroke="#EEEEEE"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0719 14.75C18.0719 15.3125 17.9144 15.845 17.6369 16.295C17.4794 16.565 17.2769 16.805 17.0444 17C16.5194 17.4725 15.8294 17.75 15.0719 17.75C13.9769 17.75 13.0244 17.165 12.5069 16.295C12.2294 15.845 12.0719 15.3125 12.0719 14.75C12.0719 13.805 12.5069 12.9575 13.1969 12.41C13.7144 11.9975 14.3669 11.75 15.0719 11.75C16.7294 11.75 18.0719 13.0925 18.0719 14.75Z"
        stroke="#EEEEEE"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1893 14.735H13.9543"
        stroke="#EEEEEE"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0718 13.64V15.8825"
        stroke="#EEEEEE"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Faq() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5719 14.3226H10.5719L7.23439 16.5425C6.73939 16.8725 6.0719 16.5201 6.0719 15.9201V14.3226C3.8219 14.3226 2.3219 12.8226 2.3219 10.5726V6.07251C2.3219 3.82251 3.8219 2.32251 6.0719 2.32251H13.5719C15.8219 2.32251 17.3219 3.82251 17.3219 6.07251V10.5726C17.3219 12.8226 15.8219 14.3226 13.5719 14.3226Z"
        stroke="#EEEEEE"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.82188 9.01999V8.86252C9.82188 8.35252 10.1369 8.08251 10.4519 7.86501C10.7594 7.65501 11.0669 7.38501 11.0669 6.89001C11.0669 6.20001 10.5119 5.64499 9.82188 5.64499C9.13188 5.64499 8.5769 6.20001 8.5769 6.89001"
        stroke="#EEEEEE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.81852 10.8125H9.82527"
        stroke="#EEEEEE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Support() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3219 14.645H13.7519C13.1519 14.645 12.5819 14.8775 12.1619 15.2975L10.8794 16.565C10.2944 17.1425 9.3419 17.1425 8.7569 16.565L7.4744 15.2975C7.0544 14.8775 6.4769 14.645 5.8844 14.645H5.3219C4.0769 14.645 3.0719 13.6475 3.0719 12.4175V4.23499C3.0719 3.00499 4.0769 2.00751 5.3219 2.00751H14.3219C15.5669 2.00751 16.5719 3.00499 16.5719 4.23499V12.4175C16.5719 13.64 15.5669 14.645 14.3219 14.645Z"
        stroke="#EEEEEE"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.0719 7.37003C6.0719 6.67253 6.6419 6.10248 7.3394 6.10248C8.0369 6.10248 8.6069 6.67253 8.6069 7.37003C8.6069 8.78003 6.6044 8.93002 6.1619 10.2725C6.0719 10.55 6.3044 10.8275 6.5969 10.8275H8.6069"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8518 10.82V6.53752C12.8518 6.34252 12.7243 6.16998 12.5368 6.11748C12.3493 6.06498 12.1469 6.13998 12.0419 6.30498C11.5019 7.17498 10.9168 8.16499 10.4068 9.03499C10.3243 9.17749 10.3243 9.36499 10.4068 9.50749C10.4893 9.64999 10.6468 9.73995 10.8193 9.73995H13.5718"
        stroke="#EEEEEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
