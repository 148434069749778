import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./faq.css";

function Faq() {
  return (
    <div className="skill-faq-content-container">
      <h2>FAQS</h2>
      <div className="skill-faq-content">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="skill-faq-head">
                <span>01</span>
                <h3>Alright, but what exactly do you do?</h3>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="skill-faq-body">
                <p>
                  Timperdiet gravida scelerisque odio nunc. Eget felis, odio
                  bibendum quis eget sit lorem donec diam.Volutpat sed orci
                  turpis sit dolor est a pretium eget. Vitae turpis orci vel
                  tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem
                  venenatis urna.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="skill-faq-head">
                <span>02</span>
                <h3>
                  I dont need a brand strategist but I need help executing an
                  upcoming campaign. Can we still work together?
                </h3>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="skill-faq-body">
                <p>
                  Timperdiet gravida scelerisque odio nunc. Eget felis, odio
                  bibendum quis eget sit lorem donec diam. Volutpat sed orci
                  turpis sit dolor est a pretium eget. Vitae turpis orci vel
                  tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem
                  venenatis urna.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className="skill-faq-head">
                <span>03</span>
                <h3>Are your rates competitive?</h3>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="skill-faq-body">
                <p>
                  Timperdiet gravida scelerisque odio nunc. Eget felis, odio
                  bibendum quis eget sit lorem donec diam. Volutpat sed orci
                  turpis sit dolor est a pretium eget. Vitae turpis orci vel
                  tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem
                  venenatis urna.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <div className="skill-faq-head">
                <span>04</span>
                <h3>Why do you have a monthly project cap?</h3>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="skill-faq-body">
                <p>
                  Timperdiet gravida scelerisque odio nunc. Eget felis, odio
                  bibendum quis eget sit lorem donec diam. Volutpat sed orci
                  turpis sit dolor est a pretium eget. Vitae turpis orci vel
                  tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem
                  venenatis urna.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}
export default Faq;
