import React from "react"
import Table from "react-bootstrap/Table";
import Select from "react-select"
import Setting from "../Setting"
import filterImg from "../../asset/image/settingIcon/filter.svg"

import "./SessionSetting.css"

const options = [
  { value: "Filter 1", label: "Filter 1" },
  { value: "Filter 2", label: "Filter 2" },
  { value: "Filter 3", label: "Filter 3" },
  { value: "Filter 4", label: "Filter 4" }
]
const styles = {
  option: (provided) => ({
    ...provided,
    background: "var(--main-bg)",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "24px",
    height: "34px",
    cursor: "pointer",
    border: "none",
    ":hover": {
      background: "var(--input-bg)",
    },
  }),
  input: (base) => ({
    ...base,
    color: "#fff",
    paddingLeft: "36px",
    width: "111px",
    height: "36px",
  }),

  control: (base) => ({
    ...base,
    background: "var(--input-bg)",
    border: "none",
    boxShadow: "none",
    width: "111px",
    color: "#fff",
    cursor: "pointer",
  }),

  menu: (provided) => ({
    ...provided,

    width: "150px",
    height: "146px",
    background: "var(--main-bg)",
    borderRadius: "5px",
    color: "#fff",
  }),

}

function SessionSetting() {
  return (
    <Setting>
      <div className="session-page">
        <div className="session-filterDropdown">
          <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} placeholder="Filter" options={options} isSearchable={false} styles={styles} />
          <img src={filterImg} alt="filterImg" />
        </div>
        <div className="session-table">
          <div>
            <Table responsive>
              <tbody>
                <tr className="tableHeadings">
                  <td>Browser</td>
                  <td>Near</td>
                  <td>IP Address</td>
                  <td>Last Used</td>
                  <td>Action</td>
                </tr>
                <tr>
                  <td>Chrome (Unknown)</td>
                  <td>IN, Lucknow</td>
                  <td>183.83.46.188</td>
                  <td>54 minutes ago</td>
                  <td>Current</td>
                </tr>
                <tr>
                  <td>Chrome (Unknown)</td>
                  <td>IN, Lucknow</td>
                  <td>183.83.46.188</td>
                  <td>11 days ago</td>
                  <td className="removedWarning">Remove Session</td>
                </tr>
                <tr>
                  <td>Chrome (Unknown)</td>
                  <td>IN, Lucknow</td>
                  <td>183.83.46.188</td>
                  <td>11 days ago</td>
                  <td className="removedDisable">Removed</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="table-button">
            <span>Previous</span>
            <span>Next</span>
          </div>
        </div>
      </div>
    </Setting>
  )
}

export default SessionSetting
