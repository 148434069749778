import React from "react";
import { Form } from "react-bootstrap";
import { EmojiIcon, SendBtn } from "../chat/globalChat";
import Layout from "../layout/layout";
import "./support.css";
import "../chat/chat.css";
import UserPic1 from "../asset/image/chat/user1.svg";
import UserPic2 from "../asset/image/chat/user2.svg";
import UserPic3 from "../asset/image/chat/user3.svg";
import UserPic4 from "../asset/image/chat/user4.svg";
import UserPic5 from "../asset/image/chat/user5.svg";
import UserPic6 from "../asset/image/chat/user6.svg";

function Support() {
  // eslint-disable-next-line no-unused-vars
  const ChatMsg = [
    {
      profile: UserPic1,
      name: "Albert Flores",
      role: "admin",
    },
    {
      profile: UserPic2,
      name: "Qweike",
      role: "admin",
    },
    {
      profile: UserPic3,
      name: "Bessie Cooper",
      role: "user",
    },
    {
      profile: UserPic4,
      name: "Zafar",
      role: "admin",
    },
    {
      profile: UserPic5,
      name: "Akram",
      role: "admin",
    },
    {
      profile: UserPic6,
      name: "Bessie Cooper",
      role: "user",
    },
    {
      profile: UserPic1,
      name: "Albert Flores",
      role: "user",
    },
    {
      profile: UserPic2,
      name: "Qweike",
      role: "admin",
    },
    {
      profile: UserPic3,
      name: "Bessie Cooper",
      role: "admin",
    },
  ];

  return (
    <Layout>
      <div className="support-container">
        <div className="support-detail">
          {/* <div className="support-detail-chat"> */}
          <p>Support</p>
          <h3>Hey How Can Help You</h3>
          <div className="support-detail-chat">
            {ChatMsg.map((item) => (
              <div
                className={`${
                  item.role === "admin"
                    ? "message-wrapper-reverse"
                    : "message-wrapper"
                }`}
              >
                <img src={item.profile} alt="" />
                <div className="message-body">
                  <div className="chat-user-name">
                    <h6>{item.name}</h6>
                    <span className="chat-time">12:14</span>
                  </div>
                  <div className="user-chat-box">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="send-chat-wrapper">
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Enter your message here..."
              />
              <div className="send-message-btn">
                <span>
                  <EmojiIcon />
                </span>
                <div className="send-chat-icon">
                  <SendBtn />
                </div>
              </div>
            </Form.Group>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Support;
