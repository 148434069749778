/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import doller from "../../../assets/images/game/doller.svg";
import mines from "../../../assets/images/mines/mines.svg";
import whitegem from "../../../assets/images/mines/whitegem.svg";
import redgem from "../../../assets/images/header/gem.png";
// import xicon from "../../../assets/images/mines/x.svg";

function ManualTab() {
  const [gameActive, setGameActive] = useState(false);
  const [betSize, setBetSize] = useState("");
  const [numberOfMines, setNumberOfMines] = useState("");
  const [currentProfit, setCurrentProfit] = useState(0.0);
  const updateBetSizeAmount = (event) => {
    const inputValue = parseFloat(event.target.value);
    if (inputValue > 1000000 || inputValue < 0) {
      return;
    }
    const roundedBetSize = Math.round((inputValue + Number.EPSILON) * 100) / 100;
    setBetSize(roundedBetSize);
  };

  const updateBetSize = (updateType) => {
    let newBetSize = 0;
    if (updateType == "/2") newBetSize = betSize / 2;
    else if (updateType == "X2") newBetSize = betSize * 2;
    else if (updateType == "Max") newBetSize = 69;
    const roundedBetSize = Math.round((newBetSize + Number.EPSILON) * 100) / 100;
    if (roundedBetSize > 100000) return;
    setBetSize(roundedBetSize);
  };

  const updateMinesCount = (minesCount) => {
    if (minesCount.target.value > 25 || minesCount.target.value < 1) return;

    setNumberOfMines(minesCount.target.value);
  };

  const toggleGameStatus = () => {
    setGameActive(!gameActive);
    setCurrentProfit(0.0);
  };

  return (
    <div className="manual-tab">
      <Form>
        <div className="mines-bet-box">
          <Form.Group className="mines-bet-box-inp">
            <Form.Label>Bet Amount</Form.Label>
            <div className="bet-input pdd-200">
              <img src={doller} alt="" />

              {gameActive ? (
                <Form.Control
                  disabled
                  type="number"
                  placeholder="0.00"
                  value={betSize}
                  onChange={updateBetSizeAmount}
                />
              ) : (
                <Form.Control
                  type="number"
                  placeholder="0.00"
                  value={betSize}
                  onChange={updateBetSizeAmount}
                />
              )}

              <div className="bet-input-btn">
                {gameActive ? (
                  <>
                    <Button disabled onClick={() => updateBetSize("/2")}>
                      1/2
                    </Button>
                    <Button disabled onClick={() => updateBetSize("X2")}>
                      2x
                    </Button>
                    <Button disabled onClick={() => updateBetSize("Max")}>
                      Max
                    </Button>
                  </>
                ) : (
                  <>
                    <Button onClick={() => updateBetSize("/2")}>1/2</Button>
                    <Button onClick={() => updateBetSize("X2")}>2x</Button>
                    <Button onClick={() => updateBetSize("Max")}>Max</Button>
                  </>
                )}
              </div>
            </div>
          </Form.Group>

          <Form.Group className="bet-input-max-width">
            <Form.Label>Mines</Form.Label>
            <div className="bet-input">
              <img src={mines} alt="" />
              {gameActive ? (
                <Form.Control
                  disabled
                  type="number"
                  placeholder="20"
                  onChange={updateMinesCount}
                  value={numberOfMines}
                />
              ) : (
                <Form.Control
                  type="number"
                  placeholder="20"
                  onChange={updateMinesCount}
                  value={numberOfMines}
                />
              )}
            </div>
          </Form.Group>

          {gameActive ? (
            <Form.Group>
              <Form.Label>Gems</Form.Label>
              <div className="bet-input">
                <img src={whitegem} alt="" />
                <Form.Control disabled type="number" placeholder="3" />
              </div>
            </Form.Group>
          ) : null}

          {gameActive ? (
            <Form.Group>
              <Form.Label>Profit</Form.Label>
              <div className="bet-input">
                <img src={redgem} alt="" />
                <Form.Control
                  disabled
                  type="number"
                  placeholder="50.00"
                  value={currentProfit}
                />
              </div>
            </Form.Group>
          ) : null}
        </div>
        <div className="roll-dice">
          <Button onClick={toggleGameStatus}>
            <span>{gameActive ? "Cashout" : "Place Bet"}</span>
          </Button>
        </div>
      </Form>
      <div className="change-seed">
        <Button>Change Seeds</Button>
      </div>
    </div>
  );
}

export default ManualTab;
