import React, { useContext } from "react";
// import PropsTypes from "prop-types";
import { Link } from "react-router-dom";
import logo from "../asset/image/footer/logo.png";
import logodark from "../asset/image/header/skillPlayz-dark.png";
import "./footer.css";
import { ThemeContext } from "../contexts/ThemeContext";

function Footer() {
  const mode = useContext(ThemeContext);
  return (
    <div className="footer-content">
      <div className="container">
        <div className="footer-content-container">
          <div className="footer-logo">
            <img
              src={mode.theme === "dark" ? logo : logodark}
              alt="logo"
              width="150px"
            />
          </div>
          <div className="footer-details">
            <div className="footer-copy-right">
              <span>Copyright 2022 Company Name.</span>
            </div>
            <div className="footer-detail-right">
              <ul>
                <li>
                  <Link to="/privacyPolicy">Privacy Policy</Link>
                </li>
                {/* <li>
                  <Link
                    to="/termsncondtn
                  "
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/">Cookie Policy</Link>
                </li> */}
                <li>
                  <Link to="/contactUs">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Footer.propTypes = {
//   isToggle: PropsTypes.element.isRequired,
// };

export default Footer;
