import React, { useState } from "react";
import contactus from "../../asset/image/contactus/Illustration.svg";
import ColorButton from "../../global/colorbutton/button";
import ContactUsModal from "../../Modal/ContactUsModal";
import "./contactus.css";

function ContactUs() {
  const [showCTA, setShowCTA] = useState(false);

  const handleClose = () => setShowCTA(false);
  const handleShow = () => setShowCTA(true);
  return (
    <div className="contactus-content-container">
      <div className="contactus-content">
        <div className="contactus-content-left">
          <h2>
            We would
            {" "}
            <span>Love</span>
            {" "}
            to Hear From You
          </h2>
          <p>
            Whether you are curious about features, a free trail, we are ready to
            answer any and all questions.
          </p>
          <ColorButton btnName="Contact Us" handleShow={handleShow} role="presentation" />
        </div>
        <div className="contactus-content-right">
          <img src={contactus} alt="illustration" />
        </div>
      </div>
      <ContactUsModal handleClose={handleClose} show={showCTA} />
    </div>
  );
}
export default ContactUs;
