import React, { useState } from "react";
import { Link } from "react-router-dom";

import game1 from "../asset/image/gamegrid/game1.svg";
import game2 from "../asset/image/gamegrid/game2.svg";
import game3 from "../asset/image/gamegrid/game3.svg";
import game4 from "../asset/image/gamegrid/game4.svg";
import game5 from "../asset/image/gamegrid/game5.svg";
import game6 from "../asset/image/gamegrid/game6.svg";
import game7 from "../asset/image/gamegrid/game7.svg";
import game8 from "../asset/image/gamegrid/game8.svg";
import ColorButton from "../global/colorbutton/button";
import LudoPage from "../LudoGame/LudoPage";

function HomeGameGrid() {
  const [showLudoModal, setShowLudoModal] = useState(false);

  const handleClose = () => setShowLudoModal(false);
  // const handleShow = () => setShowLudoModal(true);

  return (
    <div className="home-grid-content-container">
      <div className="home-grid-head">
        <h2>
          Skillplayz
          {" "}
          <span> Originals </span>
        </h2>
        <Link to="/allGames">
          <ColorButton btnName="View More" />
        </Link>
      </div>
      <div className="home-grid-content">
        <div className="home-grid-game">
          <img src={game1} alt="game" />
        </div>
        <div className="home-grid-game">
          <img src={game2} alt="game" />
        </div>
        <Link to="/dice">
          <div
            className="home-grid-game"
          // onClick={handleShow}
            role="presentation"
          >

            <img src={game3} alt="game 3" />

          </div>
        </Link>
        <div className="home-grid-game">
          <img src={game4} alt="game" />
        </div>
        <div className="home-grid-game">
          <img src={game5} alt="game" />
        </div>
        <div className="home-grid-game">
          <img src={game6} alt="game" />
        </div>
        <Link to="/mines">
          <div className="home-grid-game">
            <img src={game7} alt="game" />
          </div>
        </Link>
        <Link to="/roulette ">
          <div className="home-grid-game">
            <img src={game8} alt="game" />
          </div>
        </Link>
      </div>
      <LudoPage handleClose={handleClose} show={showLudoModal} />
    </div>
  );
}
export default HomeGameGrid;
