import React from "react";
import icon1 from "../asset/image/sidebar/icon-linkedin.svg";
import icon2 from "../asset/image/sidebar/icon-instagram.svg";
import icon3 from "../asset/image/sidebar/icon-twitch.svg";
import icon4 from "../asset/image/sidebar/icon-twitter.svg";

function SocialMedia() {
  return (
    <div className="sidebar-text-content">
      <h4>Social Media</h4>
      <div className="soical-media-icon">
        <div className="soical-icon">
          <img src={icon1} alt="" />
        </div>
        <div className="soical-icon">
          <img src={icon2} alt="" />
        </div>
        <div className="soical-icon">
          <img src={icon3} alt="" />
        </div>
        <div className="soical-icon">
          <img src={icon4} alt="" />
        </div>
      </div>
    </div>
  );
}
export default SocialMedia;
