import React, { useState } from "react";
import { ThemeContext, themes } from "../contexts/ThemeContext";

function DarkMode() {
  const [isMode, setIsmode] = useState(true);
  return (
    <div className="sidebar-text-content">
      <div className="darkmode-toggle">
        <ThemeContext.Consumer>
          {({ changeTheme }) => (
            <input
              type="checkbox"
              className="toggle-button"
              onChange={() => {
                setIsmode(!isMode);
                changeTheme(isMode ? themes.light : themes.dark);
              }}
            />
          )}
        </ThemeContext.Consumer>
      </div>
    </div>
  );
}

export default DarkMode;
