import React from "react";
import PropsTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Container, Dropdown } from "react-bootstrap";
// import policyImg from "../asset/image/policies/Vector (1).svg";
import Layout from "../layout/layout";

import "./Policies.css";

function Policies({ children }) {
  const location = useLocation();
  return (
    <Layout>
      <Container>
        <div className="policiesPage">
          <div className="polices-head">
            {/* <img src={policyImg} alt="" /> */}
            <Policyimg />
            <h4>Privacy Policy</h4>
          </div>
          <div className="policesMenu-page">
            <div className="policies-menu">
              <ul className="policies-menuList">
                <li>Coin Mixing</li>
                <li>Cookies Policy</li>
                <Link
                  to="/privacyPolicy"
                  className={
                    location.pathname === "/privacyPolicy" ? "active" : ""
                  }
                >
                  <li>Privacy Policy</li>
                </Link>
                <Link
                  to="/termsncondtn"
                  className={
                    location.pathname === "/termsncondtn" ? "active" : ""
                  }
                >
                  {" "}
                  <li>Terms & Conditons</li>
                </Link>
              </ul>
            </div>
            <div className="policies-menu-mobile">
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  {location.pathname === "/privacyPolicy"
                    ? "Privacy Policy"
                    : location.pathname === "/termsncondtn"
                      ? "Terms & Condition"
                      : ""}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link
                    to="/privacyPolicy"
                    className={
                      location.pathname === "/privacyPolicy" ? "active" : ""
                    }
                  >
                    <li>Privacy Policy</li>
                  </Link>
                  <Link
                    to="/termsncondtn"
                    className={
                      location.pathname === "/termsncondtn" ? "active" : ""
                    }
                  >
                    {" "}
                    <li>Terms & Conditons</li>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="policiesContent">{children}</div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
Policies.propTypes = {
  children: PropsTypes.element.isRequired,
};
export default Policies;

export function Policyimg() {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99967 13.167C7.23579 13.167 7.43384 13.087 7.59384 12.927C7.75329 12.7675 7.83301 12.5698 7.83301 12.3337V9.00033C7.83301 8.76421 7.75329 8.56616 7.59384 8.40616C7.43384 8.24671 7.23579 8.16699 6.99967 8.16699C6.76356 8.16699 6.56579 8.24671 6.40634 8.40616C6.24634 8.56616 6.16634 8.76421 6.16634 9.00033V12.3337C6.16634 12.5698 6.24634 12.7675 6.40634 12.927C6.56579 13.087 6.76356 13.167 6.99967 13.167ZM6.99967 6.50033C7.23579 6.50033 7.43384 6.42033 7.59384 6.26033C7.75329 6.10088 7.83301 5.9031 7.83301 5.66699C7.83301 5.43088 7.75329 5.23283 7.59384 5.07283C7.43384 4.91338 7.23579 4.83366 6.99967 4.83366C6.76356 4.83366 6.56579 4.91338 6.40634 5.07283C6.24634 5.23283 6.16634 5.43088 6.16634 5.66699C6.16634 5.9031 6.24634 6.10088 6.40634 6.26033C6.56579 6.42033 6.76356 6.50033 6.99967 6.50033ZM6.99967 17.2712H6.79134C6.7219 17.2712 6.6594 17.2573 6.60384 17.2295C4.7844 16.66 3.2844 15.5314 2.10384 13.8437C0.923286 12.1564 0.333008 10.292 0.333008 8.25033V4.31283C0.333008 3.9656 0.433841 3.6531 0.635508 3.37533C0.836619 3.09755 1.0969 2.89616 1.41634 2.77116L6.41634 0.896159C6.61079 0.826714 6.80523 0.791992 6.99967 0.791992C7.19412 0.791992 7.38856 0.826714 7.58301 0.896159L12.583 2.77116C12.9025 2.89616 13.163 3.09755 13.3647 3.37533C13.5658 3.6531 13.6663 3.9656 13.6663 4.31283V8.25033C13.6663 10.292 13.0761 12.1564 11.8955 13.8437C10.715 15.5314 9.21495 16.66 7.39551 17.2295C7.32606 17.2573 7.19412 17.2712 6.99967 17.2712Z"
        fill="white"
      />
    </svg>
  );
}
