import React from "react";
import { Tabs, Tab } from "react-bootstrap";
// import Layout from "../../layout/layout";
import DiceHistory from "../dice/history";
import "./mines.css";
import ManualTab from "./manualtab";
import gemicon from "../../../assets/images/mines/Gem.svg";
import greenTile from "../../../assets/images/mines/green-tile.svg";
import redTile from "../../../assets/images/mines/red-tile.svg";
import AutomatedTab from "./automatedtab";
import Layout from "../../layout/layout";
// import redgem from "../../../assets/images/header/gem.png";

function Mines() {
  return (
  // <Layout>
    <Layout>
      <div className="mines">
        <div className="container">
          <div className="mines-game">
            <div className="mines-tab">
              <Tabs defaultActiveKey="manual" id="uncontrolled-tab-example">
                <Tab eventKey="manual" title="Manual">
                  <ManualTab />
                </Tab>
                <Tab eventKey="automated" title="Automated">
                  <AutomatedTab />
                </Tab>
              </Tabs>
            </div>
            <div className="mines-right-section">
              {/* add class 'winner-game' on 'mines-game-sec  when game win or loss */}
              <div className="mines-game-sec">
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles active-game-tile">
                  <img src={gemicon} alt="icon" />
                  <p>+50.00</p>
                </div>
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles  win-game-tile-red">
                  <img src={redTile} alt="icon" />
                </div>
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles win-game-tile-green">
                  <img src={greenTile} alt="icon" />
                </div>
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
                <div className="mines-game-tiles" />
              </div>
              {/* 'mines-winner-box' for winner section */}
              {/* 'mines-loss-box' for loss section */}
              {/* <div className="mines-winner-loss">
                <div className="mines-winner-box">
                  <h6>2.59x</h6>
                  <p>
                    <img src={redgem} alt="" /> 245.91
                  </p>
                </div>
              </div> */ }
            </div>
          </div>

          <DiceHistory />
        </div>
      </div>
    </Layout>
  );
}

export default Mines;
