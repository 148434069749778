import React from "react";
import PropsTypes from "prop-types";
// import SearchDropdown from "../SearchDropdown/SearchDropdown";
import "./input.css";

function Input(props) {
  const {
    searchToggle,
    onChange,
    type,
    className,
    name,
    id,
    placeholder,
    defaultValue,
    value,
  } = props;
  return (
    <>
      <div className={`skill-search ${searchToggle ? "mobileSearchShow" : ""}`}>
        <input
          type={type}
          placeholder={placeholder}
          className={`form-control ${className}`}
          onChange={onChange}
          name={name}
          id={id}
          defaultValue={defaultValue}
          value={value}
          {...props}
        />
      </div>
      {/* <SearchDropdown /> */}
    </>
  );
}
Input.defaultProps = {
  value: "",
  defaultValue: "",
  placeholder: "",
  id: "",
  className: "",
};

Input.propTypes = {
  value: PropsTypes.any,
  defaultValue: PropsTypes.any,
  placeholder: PropsTypes.string,
  id: PropsTypes.string,
  name: PropsTypes.string.isRequired,
  searchToggle: PropsTypes.bool.isRequired,
  handlesearchshow: PropsTypes.func.isRequired,
  onChange: PropsTypes.func.isRequired,
  type: PropsTypes.string.isRequired,
  className: PropsTypes.string,
};
export default Input;
