import React, { useContext, useState } from "react";
import PropsTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";
import msg from "../asset/image/header/message-icon.svg";
import logo from "../asset/image/header/logo.png";
import lens from "../asset/image/header/search-icon.svg";
import user from "../asset/image/header/user-icon.svg";
import GlobalChat from "../chat/globalChat";
import IconButton from "../global/iconbutton/iconbutton";
import Input from "../global/input/input";
import TransparentButton from "../global/transparentbutton/transparentbutton";
import "./header.css";
import { ThemeContext } from "../contexts/ThemeContext";
import logodark from "../asset/image/header/skillPlayz-dark.png";

function Header({ sidebarToggle }) {
  const [showChat, setShowChat] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const tokenId = localStorage.getItem("loginToken");

  const handleChatClose = () => setShowChat(false);
  const handleChatShow = () => setShowChat(true);
  const mode = useContext(ThemeContext);
  const handlesearchshow = () => {
    setSearchToggle(!searchToggle);
  };
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };
  return (
    <div className="header-content">
      <Navbar expand="lg">
        <Container>
          <div className="header-content-container">
            <Input
              placeholder="Search..."
              sidebarToggle={sidebarToggle}
              className={`${!sidebarToggle ? "" : "helo"}`}
              value={searchValue}
              onChange={handleSearch}
            />
            <div className="skill-header-btn">
              {!tokenId && <TransparentButton />}
              {!tokenId && <IconButton />}
              <div
                className="skill-massage"
                onClick={handleChatShow}
                role="presentation"
              >
                <img src={msg} alt="icon" />
              </div>
            </div>
          </div>
          <div className="headerNavbar-mobileView">
            <div className="logoFor-mobileMenu">
              <Link to="/">
                {" "}
                <img
                  src={mode.theme === "dark" ? logo : logodark}
                  alt="logo"
                  width="150px"
                />
              </Link>
            </div>
            <div className="mobileView-icons">
              <div
                className="search-icon"
                onClick={handlesearchshow}
                role="presentation"
              >
                <img src={lens} alt="" />
              </div>
              {searchToggle ? <Input /> : ""}

              <div className="skill-icon">
                <Link to="/login">
                  <img src={user} alt="icon" />
                </Link>
              </div>
              <div
                className="skill-massage"
                onClick={handleChatShow}
                role="presentation"
              >
                <img src={msg} alt="icon" />
              </div>
            </div>
          </div>
        </Container>
      </Navbar>
      <GlobalChat showChat={showChat} handleChatClose={handleChatClose} />
    </div>
  );
}

Header.propTypes = {
  sidebarToggle: PropsTypes.element.isRequired,
  // isToggle: PropsTypes.element.isRequired,
};

export default Header;
