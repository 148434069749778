import Select from "react-select";
import PropsTypes from "prop-types";
import "../LudoPage.css";

function SelectDropDown({ options, value, onChange }) {
  // console.log('these are the options',options);
  const styles = {
    option: (provided) => ({
      ...provided,
      background: "var(--input-bg)",
      color: "#fff",
      fontWeight: "400",
      fontSize: "var(--linkfont)",
      padding: "12px",
      lineHeight: "var(--linkfont)",
      cursor: "pointer",
      border: "none",
      position: "relative",

      ":hover": {
        background: "var(--primary-bg)",
      },
    }),
    menuPortal: (base) => ({ ...base }),
    input: (base) => ({
      ...base,
      color: "#fff",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),

    control: (base) => ({
      ...base,
      background: "var(--input-bg)",
      border: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
    }),
    container: (base) => ({
      ...base,
      border: "none",
      boxShadow: "none",
    }),
    menu: (provided) => ({
      ...provided,
      padding: "0px",
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "8px",
        background: "var(--input-bg)",
      },
      "::-webkit-scrollbar-thumb": {
        background: "var(--primary-bg)",
      },
    }),
  };
  return (
    <Select
      styles={styles}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}

SelectDropDown.defaultProps = {
  value: {}
}
SelectDropDown.propTypes = {
  options: PropsTypes.array.isRequired,
  value: PropsTypes.object,
  onChange: PropsTypes.func.isRequired,
}
export default SelectDropDown
