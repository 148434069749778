import React from "react";
import { Button, Container, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import Layout from "../layout/layout";
import "./ContactUsPage.css";

function ContactUsPage() {
  return (
    <Layout>
      <Container>
        <div className="contactPage">
          <div className="contactpage-left">
            <div>
              <h2>
                <span>Contact</span>
                {" "}
                Information
              </h2>
              <p>
                Fill up the form and our Team will get back to you within 24
                hours
                {" "}
              </p>
            </div>

            <div className="contactpageDetails">
              <div>
                <Mail />
                <h4>+0123 4567 8910</h4>
              </div>
              <div>
                <Mail />
                <h4>hello@mail.com</h4>
              </div>
              <div>
                <Location />
                <h4>Lucknow, Uttar Pradesh - 226024</h4>
              </div>
            </div>
          </div>
          <div className="contactpage-right">
            <Form>
              <div className="name">
                <Form.Group className="reg-name">
                  <Form.Label>First Name </Form.Label>
                  <Form.Control type="name" placeholder="Zafar" />
                </Form.Group>
                <Form.Group className="reg-name">
                  <Form.Label>Last Name </Form.Label>
                  <Form.Control type="name" placeholder="Khan" />
                </Form.Group>
              </div>
              <Form.Group className="contact-mail">
                <Form.Label>Email </Form.Label>
                {/* <img src={sms} alt="" /> */}
                <Mail />
                <Form.Control type="email" placeholder="example@mail.com" />
              </Form.Group>
              <Form.Group className="reg-mail">
                <Form.Label>Phone Number </Form.Label>
                <PhoneInput
                  placeholder="Enter phone number"
                  value="+91"
                  searchPlaceholder=""
                  className="modalContactUs-phone"
                />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  className="modalTextArea"
                  as="textarea"
                  rows={5}
                  placeholder="Your messege here..."
                />
              </Form.Group>
              <Button variant="primary" className="submitBtn" type="submit">
                Send Message
              </Button>
            </Form>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default ContactUsPage;

export function Location() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#00cccb"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <path
          d="M11.7712 11.1045L8.94251 13.9331C8.8188 14.057 8.6719 14.1552 8.51021 14.2222C8.34852 14.2892 8.17521 14.3237 8.00017 14.3237C7.82514 14.3237 7.65183 14.2892 7.49013 14.2222C7.32844 14.1552 7.18154 14.057 7.05784 13.9331L4.22851 11.1045C3.48265 10.3586 2.97473 9.40827 2.76896 8.37371C2.5632 7.33916 2.66883 6.26681 3.07251 5.29229C3.47618 4.31777 4.15977 3.48483 5.03683 2.89881C5.91388 2.31279 6.94502 2 7.99984 2C9.05466 2 10.0858 2.31279 10.9629 2.89881C11.8399 3.48483 12.5235 4.31777 12.9272 5.29229C13.3308 6.26681 13.4365 7.33916 13.2307 8.37371C13.0249 9.40827 12.517 10.3586 11.7712 11.1045V11.1045Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.41421 8.74771C9.78929 8.37264 10 7.86393 10 7.3335C10 6.80306 9.78929 6.29435 9.41421 5.91928C9.03914 5.54421 8.53043 5.3335 8 5.3335C7.46957 5.3335 6.96086 5.54421 6.58579 5.91928C6.21071 6.29435 6 6.80306 6 7.3335C6 7.86393 6.21071 8.37264 6.58579 8.74771C6.96086 9.12278 7.46957 9.3335 8 9.3335C8.53043 9.3335 9.03914 9.12278 9.41421 8.74771Z"
          stroke="#00cccb"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function Mail() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <path
          d="M11.3335 14.4163H4.66683C2.66683 14.4163 1.3335 13.4163 1.3335 11.083V6.41634C1.3335 4.08301 2.66683 3.08301 4.66683 3.08301H11.3335C13.3335 3.08301 14.6668 4.08301 14.6668 6.41634V11.083C14.6668 13.4163 13.3335 14.4163 11.3335 14.4163Z"
          stroke="#EEEEEE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3332 6.75L9.2465 8.41667C8.55983 8.96333 7.43317 8.96333 6.7465 8.41667L4.6665 6.75"
          stroke="#EEEEEE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
