/* eslint-disable max-len */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import moment from "moment";
import logo from "../asset/image/header/logo.png";
import LoginBackground from "../global/LoginRegisterHome/LoginBackground";
import google from "../asset/image/LoginBackground/google.svg";
import "./Register.css";
import { emailRegex, passRegex } from "../../utils/authValidation";
import { registerApi } from "../../utils/userApi";
import DatePickers from "../datePicker/DatePickers";

function Register() {
  const [dateValue, setDateValue] = useState(new Date());
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      date: moment(new Date()).format("DD/MM/YYYY"),
      email: "",
      password: "",
      confirmPassword: "",
    },
    mode: "onBlur",
  });
  const navigate = useNavigate();

  const onSubmit = async (value) => {
    try {
      const { firstName, lastName, date, email, password } = value;
      const payload = { firstName, lastName, date, email, password };
      const { status, errMessage, tokens } = await registerApi(payload);

      if (status === 200) {
        localStorage.setItem("loginToken", tokens?.access?.token);
        reset();
        navigate("/");
      } else {
        setError(
          "email",
          { message: errMessage, type: "required" },
          { shouldFocus: true }
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("this is error ", e);
    }
  };
  return (
    <div className="login-page">
      <Link to="/">
        <img className="logo" src={logo} alt="logo" />
      </Link>
      <LoginBackground />

      <div className="login-content" style={{ height: "100%" }}>
        <div className="reg-form">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h4>Welcome to Skillplayz...</h4>
            <div className="name">
              <Form.Group className="reg-name" controlId="fiormBasicEmal">
                <Form.Label>
                  First Name
                  {" "}
                  <span>*</span>
                </Form.Label>
                <Form.Control
                  {...register("firstName", {
                    required: "firstName required",
                    minLength: {
                      value: 3,
                      message: "first name atleast 3 character",
                    },
                    maxLength: {
                      value: 30,
                      message: "first name should be atmost 30 character",
                    },
                  })}
                  type="text"
                  placeholder="FirstName"
                />
                {errors && errors.firstName && (
                  <span className="text-danger">
                    {errors.firstName.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="reg-name" controlId="fiormBasicEmal">
                <Form.Label>
                  Last Name
                  {" "}
                  <span>*</span>
                </Form.Label>
                <Form.Control
                  {...register("lastName", {
                    required: "lastName required",
                    minLength: {
                      value: 3,
                      message: "last name atleast 3 character",
                    },
                    maxLength: {
                      value: 30,
                      message: "last name should be atmost 30 character",
                    },
                  })}
                  type="text"
                  placeholder="LastName"
                />
                {errors && errors.lastName && (
                  <span className="text-danger">{errors.lastName.message}</span>
                )}
              </Form.Group>
            </div>
            <Form.Group className="reg-DOB" controlId="fiormBasicEmal">
              <Form.Label>
                DOB
                {" "}
                <span>*</span>
              </Form.Label>
              <DatePickers setDateValue={setDateValue} dateValue={dateValue} />
              <p role="presentation" className="dateicon">
                <i className="fa fa-calendar-plus-o" />
              </p>
              {/* <Form.Control
                {...register("date", {
                  required: "dob required",
                  validate: (val) => {
                    const totalDays = moment(new Date()).diff(
                      moment(new Date(val)),
                      "days"
                    );
                    if (parseInt(totalDays, 10) <= 6570) {
                      return "Age must be 18 or greater";
                    }
                  },
                })}
                type="date"
                name="duedate"
                placeholder="Due date"
                className="reg-form-dob"
              /> */}
              {errors && errors.date && (
                <span className="text-danger">{errors.date.message}</span>
              )}
            </Form.Group>
            <Form.Group className="reg-mail" controlId="fiormBasicEmal">
              <Form.Label>
                Email
                {" "}
                <span>*</span>
              </Form.Label>
              <Emailfunc />
              <Form.Control
                {...register("email", {
                  required: "email required",
                  pattern: {
                    value: emailRegex,
                    message: "please enter valid email",
                  },
                })}
                type="email"
                placeholder="example@mail.com"
              />
              {errors && errors.email && (
                <span className="text-danger">{errors.email.message}</span>
              )}
            </Form.Group>
            <Form.Group className="reg-mail" controlId="fiormBasicEmal">
              <Form.Label>
                Password
                {" "}
                <span>*</span>
              </Form.Label>
              {/* <img src={key} alt="" /> */}
              <Keyfunc />
              <Form.Control
                {...register("password", {
                  required: "password required",
                  pattern: {
                    value: passRegex,
                    message:
                      "A minimum 8 characters password contains a combination of uppercase and lowercase letter and number are required.",
                  },
                })}
                type="password"
                placeholder="••••••••"
              />
              {errors && errors.password && (
                <span className="text-danger">{errors.password.message}</span>
              )}
            </Form.Group>

            <Form.Group className="reg-mail" controlId="fiormBasicEmal">
              <Form.Label>
                Repeat Password
                {" "}
                <span>*</span>
              </Form.Label>
              {/* <img src={key} alt="" /> */}
              <Keyfunc />
              <Form.Control
                {...register("confirmPassword", {
                  required: "password required",
                  validate: (val) => {
                    if (watch("password") !== val) {
                      return "Your passwords do not match";
                    }
                  },
                })}
                type="password"
                placeholder="••••••••"
              />
              {errors && errors.confirmPassword && (
                <span className="text-danger">
                  {errors.confirmPassword.message}
                </span>
              )}
            </Form.Group>
            <Button variant="primary" className="loginBtn" type="submit">
              Sign Up
            </Button>
          </Form>

          <div className="login-line">
            <hr />
            Or
            {" "}
            <hr />
          </div>

          <Button variant="primary" className="regBtn" type="button">
            <img src={google} alt="" />
            Or sign in with Google
          </Button>

          <div className="notHav-acc">
            Already have an account?
            <Link to="/login">Log In now</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;

export function Emailfunc() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          d="M11.3335 14.4163H4.66683C2.66683 14.4163 1.3335 13.4163 1.3335 11.083V6.41634C1.3335 4.08301 2.66683 3.08301 4.66683 3.08301H11.3335C13.3335 3.08301 14.6668 4.08301 14.6668 6.41634V11.083C14.6668 13.4163 13.3335 14.4163 11.3335 14.4163Z"
          stroke="#EEEEEE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3332 6.75L9.2465 8.41667C8.55983 8.96333 7.43317 8.96333 6.7465 8.41667L4.6665 6.75"
          stroke="#EEEEEE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function Keyfunc() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M10 5.41672C10.3536 5.41672 10.6928 5.55719 10.9428 5.80724C11.1929 6.05729 11.3333 6.39643 11.3333 6.75005M14 6.75005C14.0002 7.37483 13.854 7.99097 13.5732 8.54908C13.2924 9.1072 12.8847 9.59177 12.3829 9.96397C11.8811 10.3362 11.2991 10.5856 10.6835 10.6924C10.0679 10.7991 9.43584 10.7602 8.838 10.5787L7.33333 12.0834H6V13.4167H4.66667V14.7501H2.66667C2.48986 14.7501 2.32029 14.6798 2.19526 14.5548C2.07024 14.4298 2 14.2602 2 14.0834V12.3594C2.00004 12.1826 2.0703 12.013 2.19533 11.8881L6.17133 7.91205C6.00497 7.36206 5.95904 6.78268 6.0367 6.21335C6.11435 5.64402 6.31375 5.0981 6.62133 4.61275C6.92891 4.1274 7.33744 3.71401 7.81913 3.40073C8.30082 3.08745 8.84435 2.88162 9.41272 2.79725C9.9811 2.71289 10.561 2.75197 11.1129 2.91184C11.6648 3.07171 12.1758 3.34861 12.6111 3.72369C13.0464 4.09878 13.3958 4.56324 13.6354 5.08548C13.8751 5.60771 13.9994 6.17545 14 6.75005Z"
          stroke="#EEEEEE"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
