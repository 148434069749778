import React from "react";
import coinimg from "../../asset/image/freecoin/freecoinimg.png";
import "./freecoin.css";

function FreeCoin() {
  return (
    <div className="freecoin-content-container">
      <div className="freecoin-content">
        <div className="freecoin-content-right">
          <img src={coinimg} alt="freecoin" />
        </div>
        <div className="freecoin-content-left">
          <h1>
            Get Your
            {" "}
            <span>Free Coins</span>
            {" "}
            Today
          </h1>
          <p>
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
            fugit, sed quia consequuntur magni dolores eos qui ratione
          </p>
        </div>
      </div>
    </div>
  );
}
export default FreeCoin;
