import React from "react";
import DatePicker from "react-date-picker";
import PropsTypes from "prop-types";
import "./datePicker.css";

function DatePickers({ setDateValue, dateValue }) {
  return (
    <DatePicker
      yearPlaceholder="yyyy"
      monthPlaceholder="mm"
      dayPlaceholder="dd"
      onChange={setDateValue}
      value={dateValue}
    />
  );
}
DatePickers.propTypes = {
  setDateValue: PropsTypes.func.isRequired,
  dateValue: PropsTypes.func.isRequired,
};
export default DatePickers;
