import React, { useEffect, useState } from "react";
import PropsTypes from "prop-types";
import { themes, ThemeContext } from "./ThemeContext";

function ThemeContextWrapper({ children }) {
  const [theme, setTheme] = useState(themes.dark);
  function changeTheme(them) {
    setTheme(them);
  }
  useEffect(() => {
    switch (theme) {
      case themes.light:
        document.body.classList.add("light");
        document.body.classList.remove("dark");
        break;
      case themes.dark:
      default:
        document.body.classList.remove("light");
        document.body.classList.add("dark");
        break;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}
ThemeContextWrapper.propTypes = {
  children: PropsTypes.object.isRequired,
};
export default ThemeContextWrapper;
