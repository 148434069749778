import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import login from "../../asset/image/header/login-icon.svg";
import "./transparentbutton.css";

function TransparentButton() {
  return (
    <div className="skill-transparent-button">
      <Link to="/login" className="LinkBtn">
        <Button>
          <img src={login} alt="icon" />
          Login
        </Button>
      </Link>
    </div>
  );
}
export default TransparentButton;
