/* eslint-disable no-console */
import React from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import LoginBackground from "../global/LoginRegisterHome/LoginBackground";
import logo from "../asset/image/header/logo.png";
import sms from "../asset/image/LoginBackground/sms.svg";
import "./ForgtPasswd.css";
import { emailRegex } from "../../utils/authValidation";
import { forgetPasswordApi } from "../../utils/userApi";

function ForgtPasswd() {
  const { register, handleSubmit, formState: { errors }, setError } = useForm({
    defaultValues: {
      email: " ",
    },
    mode: "onBlur"
  });

  const handleForget = async (value) => {
    try {
      const { status, errMessage } = await forgetPasswordApi(value)
      if (status !== 200) {
        setError("email", { message: errMessage, type: "required" }, { shouldFocus: true });
      }
    } catch (e) {
      console.log("yfgdrdcygvvjhgkbujb", e.response)
    }
  }

  return (
    <div className="login-page">
      <Link to="/">
        <img className="logo" src={logo} alt="logo" />
      </Link>
      <LoginBackground />
      <div className="login-content">
        <div className="login-form">
          <Form onSubmit={handleSubmit(handleForget)}>
            <h4> Forgot Password</h4>
            <Form.Group className="reg-passwd">
              <Form.Label>
                Email
                {" "}
                <span>*</span>
              </Form.Label>
              <img src={sms} alt="" />
              <Form.Control
                {...register("email", {
                  required: "email required",
                  pattern:
                              {
                                value: emailRegex,
                                message: "please enter valid email"
                              }

                })}
                type="email"
                placeholder="example@mail.com"
              />
              {errors && errors.email && (
              <span className="text-danger">{errors.email.message}</span>)}
            </Form.Group>
            <Button variant="primary" className="loginBtn" type="submit">
              Send OTP
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ForgtPasswd;
