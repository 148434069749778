export const gameTypejson = [
  { label: "Movable timer", value: "Movable timer" },
  { label: "Classical Game timer", value: "Classical Game timer" }
]

export const gameRoundTimeout = [
  { label: "15 seconds", value: 15 },
  { label: "30 seconds", value: 30 },
  { label: "45 seconds", value: 45 }
]

export const gameTime = [
  { label: "5 minutes", value: 5 },
  { label: "10 minutes", value: 10 },
  { label: "15 minutes", value: 15 }
]
